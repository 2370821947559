import React, { useState } from "react";
import { Box, Button, Table, Thead, Tbody, Tr, Th, Td, useTheme, IconButton } from "@chakra-ui/react";
import { FaPlus, FaPrint } from "react-icons/fa";
import Capture from "./Capture";

const Summary = ({ supplier, retailer, organisation, distributor }) => {
  const theme = useTheme();
  const [orders, setOrders] = useState([]);
  const [showCapture, setShowCapture] = useState(false);

  const handleCaptureSubmit = (order) => {
    setOrders([...orders, order]);
    setShowCapture(false);
  };

  const toggleCapture = () => {
    setShowCapture(!showCapture);
  };

  return (
    <Box p={4}>
      <Button rightIcon={<FaPlus />} onClick={toggleCapture} colorScheme="customRed" variant="outline" mb={4}>
        {showCapture ? "Hide Capture Form" : "Capture Order"}
      </Button>

      {showCapture ? (
        <Capture onSubmit={handleCaptureSubmit} supplier={supplier} retailer={retailer} organisation={organisation} distributor={distributor} />
      ) : (
        <Table variant="striped" size="sm" colorScheme="gray" borderColor="gray.200" borderWidth="2px" borderStyle="solid">
          <Thead>
            <Tr>
              <Th>Print</Th>
              <Th>Order Number</Th>
              <Th>PO Number</Th>
              <Th>Order Date</Th>
              <Th>Delivery Date</Th>
              <Th>Store Code</Th>
              <Th>Store Name</Th>
              <Th>Total Cases</Th>
              <Th>Total Cost(excl)</Th>
              <Th>VAT</Th>
              <Th>Total Cost(incl)</Th>
            </Tr>
          </Thead>
          <Tbody>
            {orders.map((order, index) => (
              <Tr key={index}>
                <Td>
                  <IconButton variant="outline" colorScheme="gray" icon={<FaPrint />} size="sm" onClick={() => console.log("print")} />
                </Td>
                <Td>{order.orderNumber}</Td>
                <Td>{order.poNumber}</Td>
                <Td>{order.orderDate}</Td>
                <Td>{order.deliveryDate}</Td>
                <Td>{order.storecode}</Td>
                <Td>{order.store_name}</Td>
                <Td>{order.totalQuantity}</Td>
                <Td>{order.totalExcl}</Td>
                <Td>{order.totalVat}</Td>
                <Td>{order.totalIncl}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </Box>
  );
};

export default Summary;
