import React, { useState } from "react";
import { Box, useTheme } from "@chakra-ui/react";

import CaptureStep1 from "./CaptureStep1";
import CaptureStep2 from "./CaptureStep2";
import CaptureStep3 from "./CaptureStep3";
import Breadcrumb from "../../../../components/Breadcrumb";

const steps = [
  { title: "Order Info", description: "" },
  { title: "Articles", description: "" },
  { title: "Finalize", description: "" },
];

const Capture = ({ onSubmit, supplier, retailer, organisation, distributor }) => {
  const theme = useTheme();
  const [step1Data, setStep1Data] = useState(null);
  const [step2Data, setStep2Data] = useState([]);

  const handleStep1Submit = (data) => {
    setStep1Data(data);
  };

  const handleStep2Submit = (data) => {
    setStep2Data(data);
  };

  const handlePrevStep = async (currentStep) => {
    if (currentStep === 1) {
      const result = await CaptureStep2.verify();
      if (!result) return false;
    }
    if (currentStep === 2) {
      const result = await CaptureStep3.verify();
      if (!result) return false;
    }
    return true;
  };
  const handleNextStep = async (currentStep) => {
    if (currentStep === 0) {
      const result = await CaptureStep1.verify(handleStep1Submit);
      if (!result) return false;
    }
    if (currentStep === 1) {
      const result = await CaptureStep2.verify();
      if (!result) return false;
    }
    return true;
  };
  const handleOrderSubmit = (order) => {
    onSubmit(order);
  };

  const stepContents = [<CaptureStep1 initialData={step1Data} onSubmit={handleStep1Submit} supplier={supplier} retailer={retailer} organisation={organisation} />, <CaptureStep2 initialData={step2Data} step1Data={step1Data} onSubmit={handleStep2Submit} supplier={supplier} retailer={retailer} organisation={organisation} />, <CaptureStep3 step1Data={step1Data} step2Data={step2Data} onSubmit={handleOrderSubmit} supplier={supplier} retailer={retailer} organisation={organisation} distributor={distributor} />];

  return (
    <Box>
      <Breadcrumb steps={steps} stepContents={stepContents} onNext={handleNextStep} onPrev={handlePrevStep} />
    </Box>
  );
};

export default Capture;
