import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Thead, Tbody, Tr, Th, Td, Button, Input, useToast, IconButton, Select } from "@chakra-ui/react";
import { FaPlus, FaEdit, FaSave } from "react-icons/fa";

const Users = ({ userid, organisation, supplier, retailer }) => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [organisations, setOrganisations] = useState([]);
  const [distributors, setDistributors] = useState([]);
  const [suppliers, setSuppliers] = useState([]);

  const toast = useToast();

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/get-users`, { userid, organisation, supplier, retailer });
      const { status, data, title } = response.data;
      setUsers(data.users.map((user) => ({ ...user, isEditing: false })));
      setRoles(data.dd_roles);
      setOrganisations(data.dd_organisations);
      setDistributors(data.dd_distributors);
      setSuppliers(data.dd_suppliers);
      toast({
        title: title,
        description: "",
        status: status,
        duration: 1500,
        isClosable: true,
      });
      setIsLoading(false);
    } catch (error) {
      toast({
        title: "Error fetching users",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  const addUser = () => {
    setUsers([...users, { userid: "", username: "", userpass: "", userdescription: "", useremail: "", role: "", isEditing: true }]);
  };

  const editUser = (index) => {
    const newUsers = users.map((user, idx) => ({ ...user, isEditing: idx === index }));
    setUsers(newUsers);
  };

  const saveUser = async (index) => {
    const user = users[index];
    if (user.username == "" || user.userpass == "" || user.useremail == "" || (user.issuperadmin == false && user.isorganisation == false && user.issupplier == false && user.isdistributor == false) || (!user.issuperadmin && user.suppliercode == "" && user.distributorcode == "") || (!user.issuperadmin && (user.organisationcode == "" || !user.organisationcode)) || (!user.issuperamin && !user.isorganisation && !user.issupplier && !user.isdistributor)) {
      toast({
        title: "Please fill in all fields",
        description: "",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/admin/ups-user`, { user });
      const newUsers = users.map((u, idx) => ({ ...u, isEditing: false }));
      setUsers(newUsers);
      toast({
        title: "User saved",
        description: "User data has been saved successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      await fetchUsers();
    } catch (error) {
      toast({
        title: "Error saving user",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleChange = (index, field, value) => {
    const newUsers = users.map((user, idx) => {
        if (idx === index) {
          if (field === "suppliercode") {
            return { ...user, [field]: value, distributorcode: '' };  // Set distributorcode to empty if suppliercode changes
          } else if (field === "distributorcode") {
            return { ...user, [field]: value, suppliercode: '' };  // Set suppliercode to empty if distributorcode changes
          } else {
            return { ...user, [field]: value };  // No change to other fields
          }
        } else {
          return user;
        }
      });
    
      setUsers(newUsers);
  };

  return (
    <>
      <Button leftIcon={<FaPlus />} colorScheme="customRed" variant="outline" onClick={addUser} m={4}>
        Add User
      </Button>
      <Table variant="striped" size="sm" colorScheme="gray" borderColor="gray.200" borderWidth="2px" borderStyle="solid">
        <Thead>
          <Tr>
            <Th>Username</Th>
            <Th>Email</Th>
            <Th>Full Names</Th>
            <Th>Role</Th>
            <Th>Organisation Code</Th>
            <Th>Supplier Code</Th>
            <Th>Distributor Code</Th>
            <Th>Password</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {users.map((user, index) => (
            <Tr key={user.userid || index}>
              <Td>{user.isEditing ? <Input value={user.username} onChange={(e) => handleChange(index, "username", e.target.value)} /> : user.username}</Td>
              <Td>{user.isEditing ? <Input value={user.useremail} onChange={(e) => handleChange(index, "useremail", e.target.value)} /> : user.useremail}</Td>
              <Td>{user.isEditing ? <Input value={user.userdescription} onChange={(e) => handleChange(index, "userdescription", e.target.value)} /> : user.userdescription}</Td>
              <Td>
                {user.isEditing && !user.isorganisation ? (
                  <Select
                    placeholder="Select role"
                    value={roles.find((role) => user[role.value])?.value || ""}
                    onChange={(e) => {
                      roles.forEach((role) => (user[role.value] = false));
                      user[e.target.value] = true;
                      handleChange(index, e.target.value, true);
                    }}
                  >
                    {roles.map((role) => (
                      <option key={role.value} value={role.value}>
                        {role.text}
                      </option>
                    ))}
                  </Select>
                ) : (
                  <>
                    {user.isorganisation &&  "Organisation"}
                    {user.issuperadmin && `${user.isorganisation && roles.some((role) => role.value === "isorganisation") ? ", " : ""}Super Admin`}
                    {user.issupplier && `${user.isorganisation || user.issuperadmin ? ", " : ""}Supplier`}
                    {user.isdistributor && `${user.isorganisation || user.issuperadmin || user.issuperadmin ? ", " : ""}Retailer`}
                  </>
                )}
              </Td>
              <Td>
                {user.isEditing && !user.issuperadmin ? (
                  <Select placeholder="Select Organisation"
                   value={user.organisationcode} onChange={(e) => handleChange(index, "organisationcode", e.target.value)}>
                    {organisations.map((org) => (
                      <option key={org.value} value={org.value}>
                        {org.text}
                      </option>
                    ))}
                  </Select>
                ) : (
                  organisations.find((org) => org.value === user.organisationcode)?.text || ""
                )}
              </Td>
              <Td>
                {user.isEditing && user.issupplier ? (
                  <Select
                    placeholder="Select supplier"
                    value={user.suppliercode}
                    onChange={(e) => {
                      suppliers.forEach((supplier) => (user[supplier.value] = false));
                      user[e.target.value] = true;
                      handleChange(index, "suppliercode", e.target.value);
                    }}
                  >
                    {suppliers.map((supplier) => (
                      <option key={supplier.value} value={supplier.value}>
                        {supplier.text}
                      </option>
                    ))}
                  </Select>
                ) : user.issupplier ? (
                  user.suppliercode
                ) : (
                  ""
                )}
              </Td>

              <Td>
                {user.isEditing && user.isdistributor ? (
                  <Select
                    placeholder="Select Distributor"
                    value={user.distributorcode}
                    onChange={(e) => {
                        distributors.forEach((distributor) => (user[distributor.value] = false));
                      user[e.target.value] = true;
                      handleChange(index, "distributorcode", e.target.value);
                    }}
                  >
                    {distributors.map((distributor) => (
                      <option key={distributor.value} value={distributor.value}>
                        {distributor.text}
                      </option>
                    ))}
                  </Select>
                ) : (
                  user.distributorcode
                )}
              </Td>

              <Td>{user.isEditing ? <Input type="text" value={user.userpass} onChange={(e) => handleChange(index, "userpass", e.target.value)} /> : "••••••••"}</Td>
              <Td>{user.isEditing ? <IconButton icon={<FaSave />} onClick={() => saveUser(index)} /> : <IconButton icon={<FaEdit />} onClick={() => editUser(index)} />}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
};

export default Users;
