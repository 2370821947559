import React, { useState } from "react";
import { Box, Button, useTheme } from "@chakra-ui/react";

import CaptureStep1 from "./CaptureStep1";
import CaptureStep2 from "./CaptureStep2";
import Breadcrumb from "../../../../components/Breadcrumb";

const steps = [
  { title: "Articles", description: "" },
  { title: "Sumbit", description: "" },
];

const Capture = ({ debrief, index, onSubmit, onCancel }) => {
  const theme = useTheme();
  const [debrief_items, setDebrief_items] = useState(debrief ? debrief.debrief_items : []);
  const [debriefData, setDebriefData] = useState(debrief ? { invoice_number: debrief.invoice_number, po_number: debrief.po_number, cases_invoiced: debrief.cases_invoiced, partial_delivery: debrief.partial_delivery } : {});

  const handleStep1Submit = (data) => {
    setDebrief_items(data);
  };

  const handleNextStep = async (currentStep) => {
    if (currentStep === 0) {
      const result = await CaptureStep1.verify(handleStep1Submit);
      if (!result) return false;
    }
    return true;
  };
  const handleDebriefSubmit = (debrief) => {
    onSubmit(debrief, index);
  };

  const stepContents = [<CaptureStep1 debrief_items={debrief_items} debriefData={debriefData} onSubmit={handleStep1Submit} />,
     <CaptureStep2 debrief_items={debrief_items} debriefData={debriefData} onSubmit={handleDebriefSubmit} />];

  return (
    <Box>
      <Breadcrumb steps={steps} stepContents={stepContents} onNext={handleNextStep} />
      <Button onClick={onCancel} mt={4} colorScheme="customRed" variant="outline">
        Cancel
      </Button>
    </Box>
  );
};

export default Capture;
