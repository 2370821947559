import React, { useState, useEffect } from "react";
import { Box, Input, Select, FormControl, FormLabel, useTheme, useToast } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";

const CaptureStep1 = ({initialData , onSubmit, supplier, retailer, organisation }) => {
  const theme = useTheme();
  const toast = useToast();
  const [poNumber, setPoNumber] = useState(initialData?.poNumber || "");
  const [orderDate, setOrderDate] = useState(initialData ? new Date(initialData.orderDate) : new Date());
  const [deliveryDate, setDeliveryDate] = useState(initialData ? new Date(initialData.deliveryDate) : new Date());

  const [stores, setStores] = useState([]);
  const [storecode, setStoreCode] = useState(initialData?.storecode || "");
  const [store_name, setStoreName] = useState(initialData?.store_name || "");

  const [errors, setErrors] = useState({ poNumber: false, storecode: false });

  useEffect(() => {
    const fetchStores = async () => {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/generic/get-dd-stores`, { retailer, organisation });
      if (response.data.status != "success") {
        toast({
          title: response.data.title,
          description: response.data.message,
          status: response.data.status,
          duration: 2500,
          isClosable: true,
        });
        return;
      }
      setStores(response.data.data.stores);
    };

    fetchStores();
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    //axios call to retrieve store description
    onSubmit({ poNumber, orderDate: orderDate.toLocaleDateString(), deliveryDate: deliveryDate.toLocaleDateString(), storecode, store_name });
  };
  const handleStoreCode = (value) => {
    const selectedStore = stores.find(store => store.storecode === value);
    setStoreCode(value);
    if (selectedStore) {
      setStoreName(`${selectedStore.store_name}`);
    }
  };
  CaptureStep1.verify = async (submitCallback) => {
    const newErrors = {
      poNumber: !poNumber,
      storecode: !storecode,
    };
    setErrors(newErrors);
    if (Object.values(newErrors).some((error) => error)) {
      return false;
    }
    submitCallback({ poNumber, orderDate: orderDate.toLocaleDateString(), deliveryDate: deliveryDate.toLocaleDateString(), storecode, store_name });
    return true;
  };

  return (
    <Box as="form" onSubmit={handleSubmit} p={4} borderWidth={1} borderRadius="md" bg="white">
      <FormControl mb={4} isInvalid={errors.poNumber}>
        <FormLabel color={theme.colors.primary1}>PO Number</FormLabel>
        <Input width="200px" value={poNumber} onChange={(e) => setPoNumber(e.target.value)} placeholder="Enter PO Number" borderColor={theme.colors.primary2} />
      </FormControl>
      <FormControl mb={4}>
        <FormLabel color={theme.colors.primary1}>Order Date</FormLabel>
        <DatePicker placeholderText="Select Order Date" selected={orderDate} onChange={(date) => setOrderDate(date)} dateFormat="yyyy/MM/dd" customInput={<Input width="200px" borderColor={theme.colors.primary2} />} />
      </FormControl>
      <FormControl mb={4}>
        <FormLabel color={theme.colors.primary1}>Delivery Date</FormLabel>
        <DatePicker placeholderText="Select Delibvery Date" selected={deliveryDate} onChange={(date) => setDeliveryDate(date)} dateFormat="yyyy/MM/dd" customInput={<Input width="200px" borderColor={theme.colors.primary2} />} />
      </FormControl>
      <FormControl mb={4} isInvalid={errors.storecode}>
        <FormLabel color={theme.colors.primary1}>Store Code</FormLabel>
        <Select width="200px" value={storecode} onChange={(e) => handleStoreCode(e.target.value)} placeholder="Select Store Code" borderColor={theme.colors.primary2}>
          {stores.map((store) => (
            <option key={store.id} value={store.storecode}>
              {`${store.storecode} ${store.store_name}`}
            </option>
          ))}
        </Select>
      </FormControl>
      <FormControl mb={4}>
        <FormLabel color={theme.colors.primary1}>Store Name</FormLabel>
        <Input disabled width="200px" value={store_name} onChange={(e) => setStoreName(e.target.value)} placeholder="Store Name" borderColor={theme.colors.primary2} />
      </FormControl>
    </Box>
  );
};

export default CaptureStep1;
