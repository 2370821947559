import React, { useEffect, useState } from "react";
import { Button, Menu, MenuButton, MenuList, MenuItem, Image, Box, useTheme } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useSelection } from "../wrappers/SelectionContext";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../tools/firebase";

const WarehouseNavigation = () => {
  const theme = useTheme();
  const { selectedWarehouse, setSelectedWarehouse, user_warehouse_access } = useSelection();
  const [logos, setLogos] = useState({});

  const handleWarehouseChange = (warehouse) => {
    setSelectedWarehouse(warehouse);
  };

  useEffect(() => {
    const fetchLogos = async () => {
      const logoUrls = {};
      for (const warehouse of user_warehouse_access) {
        try {
          const logoRef = ref(storage, `warehouse_logos/${warehouse.value}`);
          const url = await getDownloadURL(logoRef);
          logoUrls[warehouse.value] = url;
        } catch (error) {
          console.error("Error fetching logo:", error);
          const defaultLogoRef = ref(storage, `logos/blank.jpg`);
          try {
            const defaultUrl = await getDownloadURL(defaultLogoRef);
            logoUrls[warehouse.value] = defaultUrl;
          } catch (defaultError) {
            console.error("Error fetching default logo:", defaultError);
          }
        }
      }
      setLogos(logoUrls);
    };

    if (user_warehouse_access.length > 0) {
      fetchLogos();
    }
  }, [user_warehouse_access]);

  return (
    <Box display="flex" alignItems="center" m={1} width="calc(100% - 8px)" mx="4px">
      <Menu>
        <MenuButton as={Button} rightIcon={<ChevronDownIcon />} bg={"white"} _hover={{ bg: `${theme.colors.secondary1} !important`, color: "black !important" }} color="black" maxWidth="100%" minWidth="100%" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" px={1} justifyContent="space-between">
          {selectedWarehouse}
        </MenuButton>
        <MenuList bg={"white"} color="white" maxWidth="100%" maxHeight="500px" overflowY="auto">
          {user_warehouse_access.map((warehouse) => (
            <MenuItem key={warehouse.value} onClick={() => handleWarehouseChange(warehouse.value)} bg="white" _hover={{ bg: `${theme.colors.secondary2} !important`, color: "white !important" }} color="black" maxWidth="100%" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" minH="40px">
              {<Image boxSize="2rem" borderRadius="full" src={logos[warehouse.value]} alt={warehouse.text} mr="12px" />}
              <span>{warehouse.text}</span>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  );
};

export default WarehouseNavigation;
