import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, Flex, Table, Thead, Tbody, Tr, Th, Td, Button, useTheme, IconButton, Input, Switch, useToast, Spinner, Select } from "@chakra-ui/react";
import { FaPlus, FaEdit, FaSave, FaPrint, FaUpload, FaDownload } from "react-icons/fa";

import FileUpload from "./FileUpload";
import FileDownload from "./FileDownload";

const Stores = ({ userid, organisation, retailer, selectedWarehouse, selectedDistributor }) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const [data, setData] = useState([]);
  const [uploadingIndex, setUploadingIndex] = useState(null);
  const [distributors, setDistributors] = useState([]);
  const [warehouses, setWarehouses] = useState([]);

  const fetchStores = async () => {
    try {
      setLoading(true);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/get-stores`, { organisation, retailer, warehouse: selectedWarehouse, distributor: selectedDistributor });
      const { status, title, message, data } = response.data;
      setLoading(false);
      setData(data.stores);
      setDistributors(data.dd_distributors);
      setWarehouses(data.dd_warehouses);
      toast({
        title: title,
        description: message,
        status: status,
        duration: 1500,
        isClosable: true,
      });
    } catch (error) {
      const errMsg = error.response?.data?.data?.error || "Technical Error Occurred";
      setLoading(false);
      toast({
        title: "Failed!",
        description: errMsg,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchStores();
  }, [organisation, retailer]); //selectedWarehouse, selectedDistributor

  const addRow = () => {
    setData([
      ...data,
      {
        id: data.length + 2,
        storecode: "",
        store_name: "",
        store_area: "",
        store_classification: "",
        warehousecode: selectedWarehouse,
        distributorcode: selectedDistributor,
        store_deliverydate: "",
        store_orderdate: "",
        store_frequency: "",
        activeyn: true,
        isEditing: true,
      },
    ]);
  };

  const editRow = (index) => {
    const newData = [...data];
    newData[index].isEditing = true;
    setData(newData);
  };

  const saveRow = async (index) => {
    try {
      const newData = [...data];
      newData[index].isEditing = false;
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/ups-store`, { organisation, retailer, store: newData[index] });
      const { status, title, message } = response.data;
      toast({
        title: title,
        description: message,
        status: status,
        duration: 1500,
        isClosable: true,
      });
      setData(newData);
    } catch (error) {
      const errMsg = error.response?.data?.data?.error || "Technical Error Occurred";
      toast({
        title: "Failed!",
        description: errMsg,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleChange = (index, field, value) => {
    const newData = [...data];
    newData[index][field] = value;
    setData(newData);
  };

  return (
    <Box p={4} display="flex" flexDirection="column" height="85.5vh">
      <Box maxHeight="75vh" overflowY="scroll">
        <Table variant="striped" size="sm" colorScheme="gray" borderColor="gray.200" borderWidth="2px" borderStyle="solid">
          <Thead>
            <Tr>
              <Th style={{ width: "100px" }}>Store Code</Th>
              <Th style={{ width: "100px" }}>Alt Store Code</Th>
              <Th style={{ width: "200px" }}>Store Name</Th>

              <Th>Area</Th>
              <Th>Classification</Th>

              <Th>Distributor</Th>
              <Th>Warehouse</Th>

              <Th>Order Date</Th>
              <Th>Delivery Date</Th>
              <Th>Frequency</Th>

              <Th>Min Rate</Th>
              <Th>Percentage</Th>

              <Th>Active</Th>
              <Th style={{ width: "150px" }}>
                <Button rightIcon={<FaPlus />} onClick={addRow} ml={2} colorScheme="customRed" variant="outline" size="xs">
                  Add
                </Button>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.map((row, index) => (
              <Tr key={row.id}>
                <Td>{row.isEditing ? <Input type="text" width="100px" value={row.storecode} onChange={(e) => handleChange(index, "storecode", e.target.value)} /> : row.storecode}</Td>
                <Td>{row.isEditing ? <Input type="text" width="100px" value={row.store_code_alt} onChange={(e) => handleChange(index, "store_code_alt", e.target.value)} /> : row.store_code_alt}</Td>
                <Td>{row.isEditing ? <Input type="text" width="200px" value={row.store_name} onChange={(e) => handleChange(index, "store_name", e.target.value)} /> : row.store_name}</Td>

                <Td>{row.isEditing ? <Input type="text" width="100px" value={row.store_area} onChange={(e) => handleChange(index, "store_area", e.target.value)} /> : row.store_area}</Td>
                <Td>{row.isEditing ? <Input type="text" width="100px" value={row.store_classification} onChange={(e) => handleChange(index, "store_classification", e.target.value)} /> : row.store_classification}</Td>

                <Td>
                  {row.isEditing ? (
                    <Select placeholder="Select Distributor" value={row.distributorcode} onChange={(e) => handleChange(index, "distributorcode", e.target.value)}>
                      {distributors.map((org) => (
                        <option key={org.value} value={org.value}>
                          {org.text}
                        </option>
                      ))}
                    </Select>
                  ) : (
                    distributors.find((dist) => dist.value === row.distributorcode)?.text || ""
                  )}
                </Td>
                <Td>
                  {row.isEditing ? (
                    <Select placeholder="Select Warehouse" value={row.warehousecode} onChange={(e) => handleChange(index, "warehousecode", e.target.value)}>
                      {warehouses.map((org) => (
                        <option key={org.value} value={org.value}>
                          {org.text}
                        </option>
                      ))}
                    </Select>
                  ) : (
                    warehouses.find((dist) => dist.value === row.warehousecode || dist.text === row.warehousecode)?.text || ""
                  )}
                </Td>

                <Td>{row.isEditing ? <Input type="text" width="100px" value={row.store_deliverydate} onChange={(e) => handleChange(index, "store_deliverydate", e.target.value)} /> : row.store_deliverydate}</Td>
                <Td>{row.isEditing ? <Input type="text" width="100px" value={row.store_orderdate} onChange={(e) => handleChange(index, "store_orderdate", e.target.value)} /> : row.store_orderdate}</Td>
                <Td>{row.isEditing ? <Input type="text" width="70px" value={row.store_frequency} onChange={(e) => handleChange(index, "store_frequency", e.target.value)} /> : row.store_frequency}</Td>

                <Td>{row.isEditing ? <Input type="text" width="55px" value={row.store_minrate} onChange={(e) => handleChange(index, "store_minrate", e.target.value)} /> : row.store_minrate}</Td>
                <Td>{row.isEditing ? <Input type="text" width="55px" value={row.store_percentage} onChange={(e) => handleChange(index, "store_percentage", e.target.value)} /> : row.store_percentage}</Td>


                <Td>{row.isEditing ? <Switch isChecked={row.activeyn} onChange={(e) => handleChange(index, "activeyn", e.target.checked)} colorScheme="green" /> : <Switch isChecked={row.activeyn} isReadOnly colorScheme={row.activeyn ? "green" : "red"} />}</Td>
                <Td>{row.isEditing ? <IconButton icon={uploadingIndex === index ? <Spinner size="sm" /> : <FaSave />} onClick={() => saveRow(index)} ml={2} size="sm" isDisabled={uploadingIndex !== null} /> : <IconButton icon={<FaEdit />} onClick={() => editRow(index)} ml={2} size="sm" />}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      <Box flexGrow={1} />
      <Flex gap="20px">
        <FileDownload sender={"stores"} organisation={organisation} retailer={retailer} warehouse={selectedWarehouse} distributor={selectedDistributor} />
        <FileUpload sender={"stores"} organisation={organisation} retailer={retailer} warehouse={selectedWarehouse} distributor={selectedDistributor} onsave={fetchStores} />
      </Flex>
    </Box>
  );
};

export default Stores;
