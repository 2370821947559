import React from "react";
import { Box, Button, useSteps, Stepper, Step, StepIndicator, StepStatus, StepIcon, StepNumber, StepSeparator, StepTitle, StepDescription, useTheme } from "@chakra-ui/react";

const Breadcrumb = ({ steps, stepContents, onNext, ...props }) => {
  const theme = useTheme();
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  const handleNext = async () => {
    if (onNext && activeStep < steps.length - 1) {
      const canProceed = await onNext(activeStep);
      if (!canProceed) return;
    }
    setActiveStep(activeStep + 1);
  };

  const handleBack =async () => {
    if (props.onPrev && activeStep > 0) {
      const canProceed = await props.onPrev(activeStep);
      if (!canProceed) return;
    }
    setActiveStep(activeStep - 1);
  };

  return (
    <Box {...props}>
      <Stepper index={activeStep} colorScheme="customRed">
        {steps.map((step, index) => (
          <Step key={index}>
            <StepIndicator>
              <StepStatus complete={<StepIcon />} incomplete={<StepNumber />} active={<StepNumber />} />
            </StepIndicator>
            <Box flexShrink="0">
              <StepTitle>{step.title}</StepTitle>
              <StepDescription>{step.description}</StepDescription>
            </Box>
            <StepSeparator />
          </Step>
        ))}
      </Stepper>

      <Box mt={8}>{stepContents[activeStep]}</Box>

      <Box mt={4}>
        {activeStep > 0 && (
          <Button onClick={handleBack} mr={4} colorScheme="customRed">
            Back
          </Button>
        )}
        {activeStep < steps.length - 1 && (
          <Button onClick={handleNext} 
          colorScheme="customRed">
            Next
          </Button>
        )}
      </Box>
    </Box>
  );
  /*
        <Button
        type="submit"
        bg={theme.colors.primary1}
        color={theme.colors.white}
        _hover={{ bg: theme.colors.primary2, color: theme.colors.black }}
      >
        Submit
      </Button>
  */
};

export default Breadcrumb;
