import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Thead, Tbody, Tr, Th, Td, Button, IconButton, Input, Switch, useToast, Flex, Box, Image, Spinner } from "@chakra-ui/react";
import { FaPlus, FaEdit, FaSave,FaUpload } from "react-icons/fa";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../../tools/firebase";

const Suppliers = ({ userid, organisation, supplier, retailer, fetchSupplierAccess }) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [uploadingIndex, setUploadingIndex] = useState(null);
  const [isDataLoaded, setIsDataLoaded] = useState(false); 
  useEffect(() => {
    fetchSuppliers();
  }, [organisation, retailer]);

  const fetchSuppliers = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/get-suppliers`, { userid, organisation });
      setLoading(false);
      if (response.data.status === "success") {
        setData(response.data.suppliers);
        setIsDataLoaded(true);
      } else {
        toast({
          title: "Failed to load suppliers",
          description: response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: "Failed!",
        description: "Technical Error Occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const getLogoURL = async (logoName) => {
    const logoRef = ref(storage, `supplier_logos/${logoName}`);
    return await getDownloadURL(logoRef).catch(() => "");
  };

  useEffect(() => {
    const loadLogos = async () => {
      const suppliersWithLogos = await Promise.all(data.map(async (supplier) => ({
        ...supplier,
        supplier_logo: await getLogoURL(supplier.suppliercode)
      })));
      setData(suppliersWithLogos);
    };

    if (isDataLoaded) {
      loadLogos();
      setIsDataLoaded(false); 
    }
  }, [isDataLoaded]); 

  const addRow = () => {
    setData([
      ...data,
      {
        id: data.length + 1,
        suppliercode: "",
        supplier_name: "",
        supplier_logo: "",
        activeyn: true,
        isEditing: true,
        logoFile: null, // To hold the file for new uploads
      },
    ]);
  };

  const editRow = (index) => {
    const newData = [...data];
    newData[index].isEditing = true;
    setData(newData);
  };

  const saveRow = async (index) => {
    setUploadingIndex(index);
    const newData = [...data];
    const supplier = newData[index];
    if (supplier.logoFile) {
      const logoRef = ref(storage, `supplier_logos/${supplier.suppliercode}`);
      const uploadTask = uploadBytesResumable(logoRef, supplier.logoFile);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Handle progress
        },
        (error) => {
          toast({
            title: "Upload failed",
            description: "Failed to upload logo",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          setUploadingIndex(null);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            finishSave(index, supplier.supplier_logo);
          });
        }
      );
    } else {
      finishSave(index, supplier.supplier_logo);
    }
  };

  const finishSave = async (index, logoUrl) => {
    const newData = [...data];
    newData[index].isEditing = false;
    newData[index].supplier_logo = logoUrl;
    setData(newData);
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/admin/ups-supplier`, { userid, organisation, supplier: newData[index] });
      toast({
        title: "Success",
        description: "Supplier updated successfully",
        status: "success",
        duration: 1500,
        isClosable: true,
      });
      await fetchSupplierAccess();
    } catch (error) {
      toast({
        title: "Failed!",
        description: "Error saving supplier",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setUploadingIndex(null); // Reset the uploading index after save is complete
    }
  };

  const handleChange = (index, field, value) => {
    const newData = [...data];
    if (field === "logoFile") {
      newData[index].logoFile = value;
      newData[index].supplier_logo = value ? URL.createObjectURL(value) : newData[index].supplier_logo;
    } else {
      newData[index][field] = value;
    }
    setData(newData);
  };

  return (
    <Table variant="striped" size="sm" colorScheme="gray" borderColor="gray.200" borderWidth="2px" borderStyle="solid">
      <Thead>
        <Tr>
          <Th>Supplier Code</Th>
          <Th>Name</Th>
          <Th>Logo</Th>
          <Th>Active</Th>
          <Th style={{ width: "150px" }}>
            <Button rightIcon={<FaPlus />} onClick={addRow} ml={2} colorScheme="customRed" variant="outline" size="xs">
              Add
            </Button>
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {data.map((row, index) => (
          <Tr key={row.id}>
            <Td>{row.isEditing ? <Input type="text" width="150px" value={row.suppliercode} onChange={(e) => handleChange(index, "suppliercode", e.target.value)} /> : row.suppliercode}</Td>
            <Td>{row.isEditing ? <Input type="text" width="150px" value={row.supplier_name} onChange={(e) => handleChange(index, "supplier_name", e.target.value)} /> : row.supplier_name}</Td>
            <Td text-align="center" style={{ maxWidth: "100px", overflow: "hidden" }}>
              {row.isEditing ? (
                <Flex align="center" alignItems="center" justifyContent="space-between">
                  <IconButton icon={<FaUpload />} onClick={() => document.getElementById(`fileInput-${index}`).click()} size="sm" flexShrink={0} />
                  <Input id={`fileInput-${index}`} type="file" accept="image/*" onChange={(e) => handleChange(index, "logoFile", e.target.files[0])} display="none" />
                  {row.logoFile && (
                    <Box boxShadow="sm" p="2" rounded="md" bg="gray.50" flexGrow={1}>
                      <Image src={row.supplier_logo} alt="Preview" width="75%" height="50px" objectFit="cover" rounded="md" />
                    </Box>
                  )}
                </Flex>
              ) : (
                <Box boxShadow="sm" p="2" rounded="md" bg="gray.50" width="100%">
                  <Image src={row.supplier_logo} alt="Supplier Logo Logo" width="50%" height="50px" objectFit="contain" rounded="md" />
                </Box>
              )}
            </Td>
            <Td>{row.isEditing ? <Switch isChecked={row.activeyn} onChange={(e) => handleChange(index, "activeyn", e.target.checked)} colorScheme="green" /> : <Switch isChecked={row.activeyn} isReadOnly colorScheme={row.activeyn ? "green" : "red"} />}</Td>
            <Td>{row.isEditing ? <IconButton icon={uploadingIndex === index ? <Spinner size="sm" /> : <FaSave />} onClick={() => saveRow(index)} ml={2} size="sm" isDisabled={uploadingIndex !== null} /> : <IconButton icon={<FaEdit />} onClick={() => editRow(index)} ml={2} size="sm" />}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export default Suppliers;
