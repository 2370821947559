import React, { useState, useEffect } from "react";
import { Box, Button, Flex, Heading, Spinner, Table, Thead, Tbody, Tr, Td, Th, IconButton, Input } from "@chakra-ui/react";
import { FaEye, FaEdit } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import ViewItem from "../../ViewItem";
import Capture from "./Capture";
function Dispatched({ warehouse, supplier, retailer }) {
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);

  useEffect(() => {
    setLoading(true);
    const hardcodedData = [
      {
        id: "ABC100",
        date: "2024-07-18",
        supplier: "Alettes",
        totalCases: 10,
        costExcl: 1000,
        costIncl: 1100,
        supplierRef:'ALT100',
        vat: 100,
        articles: [
          { articleCode: "ALLB", articleName: "Article B", packSize: 12, quantity: 6, costExcl: 600, costIncl: 660, vat: 60, bbDate: "2024-07-07" },
          { articleCode: "ALLC", articleName: "Article C", packSize: 12, quantity: 4, costExcl: 400, costIncl: 440, vat: 40, bbDate: "2024-07-07" },
        ],
      },
      {
        id: "ABC101",
        date: "2024-07-18",
        supplier: "Alettes",
        supplierRef:'ALT101',
        totalCases: 4,
        costExcl: 900,
        costIncl: 1080,
        vat: 180,
        articles: [
          { articleCode: "ALLA", articleName: "Article A", packSize: 12, quantity: 1, costExcl: 100, costIncl: 160, vat: 60, bbDate: "2024-07-07" },
          { articleCode: "ALLB", articleName: "Article B", packSize: 12, quantity: 2, costExcl: 200, costIncl: 160, vat: 60, bbDate: "2024-07-07" },
          { articleCode: "ALLC", articleName: "Article C", packSize: 12, quantity: 1, costExcl: 600, costIncl: 660, vat: 60, bbDate: "2024-07-07" },
        ],
      },
      {
        id: "ABC102",
        date: "2024-07-18",
        supplier: "Alettes",
        supplierRef:'ALT102',
        totalCases: 10,
        costExcl: 1000,
        costIncl: 1180,
        vat: 180,
        articles: [
          { articleCode: "ALLA", articleName: "Article A", packSize: 12, quantity: 4, costExcl: 400, costIncl: 460, vat: 60, bbDate: "2024-07-07" },
          { articleCode: "ALLB", articleName: "Article B", packSize: 12, quantity: 4, costExcl: 400, costIncl: 460, vat: 60, bbDate: "2024-07-07" },
          { articleCode: "ALLC", articleName: "Article C", packSize: 12, quantity: 2, costExcl: 200, costIncl: 260, vat: 60, bbDate: "2024-07-07" },
        ],
      },
    ];

    setTimeout(() => {
      setTableData(hardcodedData);
      setLoading(false);
    }, 1000);
  }, [supplier, warehouse, retailer]);

  const handleOpenView = (item, index) => {
    setSelectedItem(item);
    setSelectedIndex(index);
    setIsPopupOpen(true);
  };

  const handleCloseView = () => {
    setIsPopupOpen(false);
    setSelectedItem(null);
    setSelectedIndex(null);
  };

  const handleOpenEdit = (item, index) => {
    setSelectedItem(item);
    setSelectedIndex(index);
    setEditMode(true);
  };
  const handleTdChange = (key, value, index) => {
    const newData = [...tableData];
    newData[index][key] = value;
    setTableData(newData);
  };

  if (loading) return <Spinner />;
  const handleEditComplete = (newArticles, newTotals, index) => {
    const updatedItem = {
      ...tableData[index],
      articles: newArticles,
      costExcl: newTotals.costExcl,
      costIncl: newTotals.costIncl,
      vat: newTotals.vat,
      completed: true
    };
    const filteredData = tableData.filter((item, i) => i !== index);
    const newTableData = [...filteredData, updatedItem];
    setTableData(newTableData);
    setEditMode(false);
    setSelectedItem(null);
    setSelectedIndex(null);
  };

  if (editMode) {
    return <Capture item={selectedItem} index={selectedIndex} onSubmit={handleEditComplete} onCancel={() => setEditMode(false)} />;
  }
  return (
    <Box>
      <Box display="flex" justifyContent="center" flexDirection={"column"} gap={4}>
        <Table variant="striped" size="sm" colorScheme="gray" borderColor="gray.200" borderWidth="2px" borderStyle="solid" width="auto">
          <Thead>
            <Tr>
              <Th>View</Th>
              <Th>ID</Th>
              <Th>Supplier Ref #</Th>
              <Th>Date</Th>
              <Th>Supplier</Th>
              <Th>Total Cases</Th>
            </Tr>
          </Thead>
          <Tbody>
            {tableData.map((item, index) => (
              <Tr key={item.id}>
                <Td>
                  <IconButton variant="outline" colorScheme="customRed" size="sm" icon={<FaEye />} aria-label="View" onClick={() => handleOpenView(item, index)} />
                </Td>
                <Td>{item.id}</Td>
                <Td>
                  <Input borderRadius={"md"} size="sm" p={1} bg="white" value={item.supplierRef} onChange={(e) => handleTdChange("supplierRef", e.target.value, index)} />
                </Td>
                <Td>
                  <DatePicker placeholderText="Select Order Date" selected={item.date} onChange={(e) => handleTdChange("date", e.toLocaleDateString("en-CA", { year: "numeric", month: "2-digit", day: "2-digit" }), index)} dateFormat="yyyy/MM/dd" customInput={<Input width="200px" />} />
                </Td>
                <Td>{item.supplier}</Td>
                <Td>
                  <Input borderRadius={"md"} size="sm" p={1} bg="white" value={item.totalCases} onChange={(e) => handleTdChange("totalCases", e.target.value, index)} />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      {selectedItem && <ViewItem isOpen={isPopupOpen} onClose={handleCloseView} item={selectedItem} />}
      {/* <ViewInvoice isOpen={isPopupOpen} onClose={handleCloseView} item={selectedItem} />} */}
    </Box>
  );
}

export default Dispatched;
