import React, { useState, useEffect, useCallback } from "react";
import { Table, Thead, Tbody, Tr, Td, Th, IconButton, Spinner, Input, Box, Flex, useDisclosure, useTheme, useToast } from "@chakra-ui/react";
import { Popover, PopoverTrigger, PopoverContent, PopoverBody, PopoverFooter, PopoverArrow, PopoverCloseButton, Button, Textarea } from "@chakra-ui/react";
import { FaEye, FaFileInvoice, FaCar, FaTimes, FaCheck, FaEdit } from "react-icons/fa";

import ViewInvoice from "./ViewInvoice";
import Capture from "./Capture"; //Edit InProgress Invoices
import CommentPopup from "./CommentPopup";

import axios from "axios";

const InProgressTable = ({ organisation, warehouse, supplier, retailer }) => {
  const theme = useTheme();
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [invoices, setInvoices] = useState([]);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [editMode, setEditMode] = useState(false);


  const [popupOrderCode, setPopupOrderCode] = useState(null);


  const [page, setPage] = useState(0); // Add state for current page
  const [limit] = useState(50);
  const [totalCount, setTotalCount] = useState(0);

  const handleViewInvoice = (invoice, index) => {
    setSelectedInvoice(invoice);
    setSelectedIndex(index);
    setIsPopupOpen(true);
  };

  const handleCloseInvoice = () => {
    setIsPopupOpen(false);
    setSelectedInvoice(null);
    setSelectedIndex(null);
  };

  const fetchInvoices = async () => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/invoices/get-in-progress`, 
      { organisation, retailer, supplier, limit, offset: page * limit });
    if (response.data.status != "success") {
      toast({
        title: response.data.title,
        description: response.data.message,
        status: response.data.status,
        duration: 2500,
        isClosable: true,
      });
      return;
    }

    setInvoices(response.data.data.orders);
    setTotalCount(response.data.data.totalCount);
    setLoading(false);
  };

  useEffect(() => {
    fetchInvoices();
  }, [organisation, supplier, retailer,page]);

  const handlePageChange = (newPage) => {
    if (newPage >= 0 && newPage * limit < totalCount) {
      // Check if the new page is within the range
      setPage(newPage);
      fetchInvoices();
    }
  };

  const handleEdit = (invoice, index) => {
    setSelectedInvoice(invoice);
    setSelectedIndex(index);
    setEditMode(true);
  };

  const handleEditComplete = async (updatedInvoice, index) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/invoices/upd-in-progress`, {
        userid: localStorage.getItem("userid"),
        invoice: updatedInvoice,
      });
      console.log(response.data);
      if (response.data.status != "success") {
        toast({
          title: response.data.title,
          description: response.data.message,
          status: response.data.status,
          duration: 2500,
          isClosable: true,
        });
        return;
      }
    } catch (error) {
      toast({
        title: "technical Error",
        description: "exception occured updating the invoioce",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }

    const newInvoices = invoices.map((invoice, i) => {
      if (i === index) {
        return updatedInvoice;
      }
      return invoice;
    });
    setInvoices(newInvoices);
    setEditMode(false);
    setSelectedInvoice(null);
    setSelectedIndex(null);
  };
  const handleCancelEdit = () => {
    setEditMode(false);
    setSelectedInvoice(null);
    setSelectedIndex(null);
  };

  const click_invoiced = async (order_code) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/invoices/upd-in-progress-invoiced`, {
        userid: localStorage.getItem("userid"),
        invoiced: true,
        order_code,
        organisation,
        supplier,
        retailer,
      });
      console.log(response.data);
      if (response.data.status != "success") {
        toast({
          title: response.data.title,
          description: response.data.message,
          status: response.data.status,
          duration: 2500,
          isClosable: true,
        });
      }
      await fetchInvoices();
    } catch (error) {
      toast({
        title: "technical Error",
        description: "exception occured updating the invoioce",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }
  };
  const click_parked = async (order_code, parked) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/invoices/upd-in-progress-parked`, {
        userid: localStorage.getItem("userid"),
        parked,
        order_code,
      });
      if (response.data.status != "success") {
        toast({
          title: response.data.title,
          description: response.data.message,
          status: response.data.status,
          duration: 2500,
          isClosable: true,
        });
      }
      await fetchInvoices();
    } catch (error) {
      toast({
        title: "technical Error",
        description: "exception occured updating the invoioce",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }
  };
  const click_cancelled = async (order_code) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/invoices/upd-in-progress-cancelled`, {
        userid: localStorage.getItem("userid"),
        cancelled: true,
        order_code,
      });
      if (response.data.status != "success") {
        toast({
          title: response.data.title,
          description: response.data.message,
          status: response.data.status,
          duration: 2500,
          isClosable: true,
        });
      }
      await fetchInvoices();
    } catch (error) {
      toast({
        title: "technical Error",
        description: "exception occured updating the invoioce",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }
  };




  if (loading) return <Spinner />;

  return (
    <Flex flexDirection={"column"}>
      <Box maxHeight="640px" overflowY={"scroll"}>
        {editMode ? (
          <Capture invoice={selectedInvoice} index={selectedIndex} organisation={organisation} warehouse={warehouse} supplier={supplier} retailer={retailer} onSubmit={handleEditComplete} onCancel={handleCancelEdit} />
        ) : (
          <Table variant="striped" size="sm" colorScheme="gray" borderColor="gray.200" borderWidth="2px" borderStyle="solid">
            <Thead>
              <Tr>
                <Th>View</Th>
                <Th>Edit</Th>
                <Th>Supplier</Th>
                <Th>Po #</Th>
                <Th>Store Code</Th>
                <Th>Store Name</Th>
                <Th>Region</Th>
                <Th>Distributor</Th>
                <Th># Cases</Th>
                <Th>Delivery Date</Th>
                <Th>Invoice Amount (incl)</Th>
                <Th>Invoice</Th>
                <Th>Park</Th>
                <Th>Cancel</Th>
                <Th>Comment</Th>
              </Tr>
            </Thead>
            <Tbody>
              {invoices.map((invoice, index) => (
                <Tr key={invoice.id}>
                  <Td>
                    <IconButton variant="outline" colorScheme="customRed" size="sm" icon={<FaEye />} aria-label="View" onClick={() => handleViewInvoice(invoice, index)} />
                  </Td>
                  <Td>
                    <IconButton variant="outline" colorScheme="green" icon={<FaEdit />} size="sm" aria-label="Park Invoice" onClick={() => handleEdit(invoice, index)} />
                  </Td>
                  <Td>{invoice.suppliercode}</Td>
                  <Td>{invoice.po_number}</Td>
                  <Td>{invoice.store_code}</Td>
                  <Td>{invoice.store_name}</Td>
                  <Td>{invoice.store_classification}</Td>
                  <Td>{invoice.distributorcode}</Td>
                  <Td>{invoice.cases}</Td>
                  <Td>{invoice.delivery_date}</Td>
                  <Td>{invoice.cost_incl}</Td>
                  <Td>
                    <IconButton onClick={() => click_invoiced(invoice.order_code)} icon={invoice.invoiced ? <FaCheck color="green" /> : <FaFileInvoice />} size="sm" border="2px" borderColor="green.300" aria-label="Open Invoice" />
                  </Td>
                  <Td>
                    <IconButton onClick={() => click_parked(invoice.order_code, !invoice.parked)} icon={invoice.parked ? <FaCheck color="gray.500" /> : <FaCar />} size="sm" border="2px" borderColor={"gray.300"} aria-label="Park Invoice" />
                  </Td>
                  <Td>
                    <IconButton onClick={() => click_cancelled(invoice.order_code)} icon={<FaTimes color="red" />} size="sm" border="2px" borderColor="red.300" aria-label="Park Invoice" />
                  </Td>
                  <Td>
                  {/* <Input borderRadius={"md"} size="sm" p={1} bg="white" 
                  value={invoice.comment} 
                  onChange={(e) => handleCommentChange(invoice.order_code,e.target.value)} /> */}
                  <CommentPopup
                    orderCode={invoice.order_code}
                    initialComment={invoice.comment || ""}
                    fetchInvoices={fetchInvoices}
                    isOpen={popupOrderCode === invoice.order_code}
                    onOpen={() => setPopupOrderCode(invoice.order_code)}
                    onClose={() => setPopupOrderCode(null)}
                  />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
        {selectedInvoice && <ViewInvoice isOpen={isPopupOpen} onClose={handleCloseInvoice} invoice={selectedInvoice} />}
      </Box>
      <Flex justifyContent="space-between" mt={4}>
        <Button
          onClick={() => handlePageChange(page - 1)}
          isDisabled={page === 0} // Disable Previous button if on the first page
        >
          Previous
        </Button>
        <Button
          onClick={() => handlePageChange(page + 1)}
          isDisabled={(page + 1) * limit >= totalCount} // Disable Next button if there are no more invoices
        >
          Next
        </Button>
      </Flex>
    </Flex>
  );
};

export default InProgressTable;
