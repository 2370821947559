import React, { useState, useEffect } from "react";
import { Box, Flex, Button, Table, Thead, Tbody, Tr, Th, Td, useColorModeValue, useTheme } from "@chakra-ui/react";
import StockStats from "../StockStats";
const CaptureStep2 = ({ articleData, stockItem, totalsData, onSubmit }) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);

  const [balance, setBalance] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      // Simulate axios request
      await new Promise((resolve) => setTimeout(resolve, 2000));

      onSubmit(articleData, totalsData);

      setLoading(false);
    } catch (error) {
      console.error("An error occurred:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (parseInt(stockItem.totalCases) == parseInt(totalsData.quantity)) {
      setBalance(true);
    } else {
      alert(false);
    }
  }, [articleData, totalsData]);

  return (
    <Box p={4} borderWidth={1} borderRadius="md" bg="white">
      <Box mb={4}>
        <Flex flexDirection={"row"} justifyContent={"space-between"}>
          <Table variant="striped" size="sm" maxWidth="400px" colorScheme="gray" borderColor="gray.200" borderWidth="2px" borderStyle="solid">
            <Thead>
              <Tr>
                <Th>ID</Th>
                <Th>Supplier Ref Nr</Th>
                <Th>Supplier</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr key={1}>
                <Td>{stockItem.id}</Td>
                <Td>{stockItem.supplierRef}</Td>
                <Td>{stockItem.supplier}</Td>
              </Tr>
            </Tbody>
          </Table>
          <StockStats totalCases={stockItem.totalCases} totalQuantity={totalsData.quantity} />
        </Flex>
      </Box>

      <Box mb={4}>
        <Table variant="striped" size="sm" colorScheme="gray" borderColor="gray.200" borderWidth="2px" borderStyle="solid">
          <Thead>
            <Tr>
              <Th>Article Number</Th>
              <Th>Article Name</Th>
              <Th>PackSize</Th>
              <Th>Quantity</Th>
              <Th>Cost (excl)</Th>
              <Th>vat</Th>
              <Th>Cost (incl)</Th>
              <Th>BB Date</Th>
            </Tr>
          </Thead>
          <Tbody>
            {articleData.map((article, index) => (
              <Tr key={index}>
                <Td> {article.articleCode} </Td>
                <Td> {article.articleName} </Td>
                <Td> {article.packSize} </Td>
                <Td>{article.quantity}</Td>
                <Td>{article.costExcl}</Td>
                <Td>{article.vat}</Td>
                <Td>{article.costIncl}</Td>
                <Td>{article.bbDate}</Td>
              </Tr>
            ))}
            <Tr borderTop={"2px solid black"}>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td style={{ fontWeight: "bold" }}>{totalsData.quantity ? totalsData.quantity : ""}</Td>
              <Td style={{ fontWeight: "bold" }}>{totalsData.costExcl > 0 ? totalsData.costExcl : ""}</Td>
              <Td style={{ fontWeight: "bold" }}>{totalsData.vat > 0 ? totalsData.vat : ""}</Td>
              <Td style={{ fontWeight: "bold" }}>{totalsData.costIncl > 0 ? totalsData.costIncl : ""}</Td>
              <Td></Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
      <Box mb={4}>
        <Button onClick={handleSubmit} isDisabled={!balance} isLoading={loading} loadingText="Submitting..." colorScheme="customRed" variant="outline">
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default CaptureStep2;
