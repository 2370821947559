import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";

const ViewOrder = ({ isOpen, onClose, orderItems }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent width="1300px" maxWidth="100vw">
        <ModalHeader>Order Items</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Table
            variant="striped"
            size="sm"
            colorScheme="gray"
            borderColor="gray.200"
            borderWidth="2px"
            borderStyle="solid"
          >
            <Thead>
              <Tr>
                <Th>Supplier Article Code</Th>
                <Th>Article Description</Th>
                <Th>Article Pack Size</Th>
                <Th>Article Cost</Th>
                <Th>Quantity</Th>
                <Th>Cost (excl)</Th>
                <Th>VAT</Th>
                <Th>Cost (incl)</Th>
                <Th>SOH</Th>
              </Tr>
            </Thead>
            <Tbody>
              {orderItems.map((item, index) => (
                <Tr key={index}>
                  <Td>{item.articlecode}</Td>
                  <Td>{item.article_desc}</Td>
                  <Td>{item.article_packsize}</Td>
                  <Td>{item.article_cost}</Td>
                  <Td>{item.quantity}</Td>
                  <Td>{item.cost_excl}</Td>
                  <Td>{item.cost_vat}</Td>
                  <Td>{item.cost_incl}</Td>
                  <Td>{item.soh}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ViewOrder;
