import React, { useState, useEffect } from "react";
import { Button, ButtonGroup, Flex, Stack, useTheme, Image } from "@chakra-ui/react";
import { useSelection } from "../wrappers/SelectionContext";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../tools/firebase";

const RetailerNavigation = () => {
  const theme = useTheme();
  const { selectedRetailer, setSelectedRetailer, retailers, availableRetailers } = useSelection();
  const [logos, setLogos] = useState({});

  // useEffect(() => {
  //   const fetchLogos = async () => {
  //     const existingLogos = {...logos};
  //     const newLogosNeeded = {};

  //     for (const retailer of retailers) {
  //       if (!existingLogos[retailer.value]) {
  //         newLogosNeeded[retailer.value] = true;
  //       }
  //     }

  //     if (Object.keys(newLogosNeeded).length > 0) {
  //       for (const retailerKey in newLogosNeeded) {
  //         try {
  //           const logoRef = ref(storage, `retailer_logos/${retailerKey}`);
  //           const url = await getDownloadURL(logoRef);
  //           existingLogos[retailerKey] = url;
  //         } catch (error) {
  //           console.error("Error fetching logo:", error);
  //           existingLogos[retailerKey] = "/default_logo.jpg"; // Adjust the path to your default logo
  //         }
  //       }
  //       setLogos(existingLogos);
  //     }
  //   };

  //   fetchLogos();
  // }, [retailers]);

  const handleRetailerChange = (retailer) => {
    if (availableRetailers[retailer]) {
      setSelectedRetailer(retailer);
    }
  };

  return (
    <Flex justify="space-between" mb={4} position="fixed" top="0" pt={2} bg="white" zIndex="1000" width="calc(100% - 170px)">
      <Flex align="center">
        <Stack direction="row" spacing={4}>
          <ButtonGroup spacing="4" mb="4">
            {retailers.map((btn) => (
              <Button
                key={btn.value}
                onClick={() => handleRetailerChange(btn.value)}
                bg={availableRetailers[btn.value] ? "white" : theme.colors.gray[200]}
                border={selectedRetailer === btn.value ? `2px solid ${theme.colors.secondary2}` : "2px solid white"}
                boxShadow="lg"
                borderRadius="md"
                _hover={{
                  border: availableRetailers[btn.value] ? `2px solid ${theme.colors.secondary2}` : `2px solid ${theme.colors.secondary2}`,
                  cursor: availableRetailers[btn.value] ? "pointer" : "not-allowed",
                }}
                color={selectedRetailer === btn.value ? theme.colors.primary1 : theme.colors.textInactive}
                overflow="hidden"
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                isDisabled={!availableRetailers[btn.value]}
                opacity={availableRetailers[btn.value] ? 1 : 0.6}
              >
                <Image width="50px" src={`${btn.value}.jpg` || "/default_logo.jpg"} alt={btn.text} mr="12px" 
                objectFit="cover" borderRadius="full" overflow="hidden" />
                {btn.text}
              </Button>
            ))}
          </ButtonGroup>
        </Stack>
      </Flex>
    </Flex>
  );
};

export default RetailerNavigation;
