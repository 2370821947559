import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Thead, Tbody, Tr, Th, Td, Button, IconButton, Input, Switch, useToast, Flex, Box, Image, Spinner } from "@chakra-ui/react";
import { FaPlus, FaEdit, FaSave, FaUpload } from "react-icons/fa";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../../tools/firebase";

const Retailers = ({ userid, organisation,  supplier,retailer, fetchRetailerAccess }) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [uploadingIndex, setUploadingIndex] = useState(null);
  const [isDataLoaded, setIsDataLoaded] = useState(false); 
  useEffect(() => {
    const fetchRetailers = async () => {
      try {
        setLoading(true);
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/get-retailers`, { userid, organisation });
        setLoading(false);
        if (response.data.status === "success") {
          setData(response.data.retailers);
          setIsDataLoaded(true);
        } else {
          toast({
            title: "Failed to load retailers",
            description: response.data.message,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        setLoading(false);
        toast({
          title: "Failed!",
          description: "Technical Error Occurred",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    };

    fetchRetailers();
  }, [organisation]);


  const getLogoURL = async (logoName) => {
    const logoRef = ref(storage, `retailer_logos/${logoName}`);
    return await getDownloadURL(logoRef).catch(() => "");
  };

  useEffect(() => {
    const loadLogos = async () => {
      const retailersWithLogos = await Promise.all(data.map(async (retailer) => ({
        ...retailer,
        retailer_logo: await getLogoURL(retailer.retailercode)
      })));
      setData(retailersWithLogos);
    };

    if (isDataLoaded) {
      loadLogos();
      setIsDataLoaded(false); 
    }
  }, [isDataLoaded]); 

  const addRow = () => {
    setData([
      ...data,
      {
        id: data.length + 1,
        retailercode: "",
        retailer_name: "",
        retailer_logo: "",
        activeyn: true,
        isEditing: true,
        logoFile: null, // To hold the file for new uploads
      },
    ]);
  };

  const editRow = (index) => {
    const newData = [...data];
    newData[index].isEditing = true;
    setData(newData);
  };

  const saveRow = async (index) => {
    setUploadingIndex(index);
    const newData = [...data];
    const retailer = newData[index];
    if (retailer.logoFile) {
      const logoRef = ref(storage, `retailer_logos/${retailer.retailercode}`);
      const uploadTask = uploadBytesResumable(logoRef, retailer.logoFile);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Handle progress
        },
        (error) => {
          console.error("Upload failed:", error);
          toast({
            title: "Upload failed",
            description: "Failed to upload logo",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          setUploadingIndex(null);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            finishSave(index, retailer.retailer_logo);
          });
        }
      );
    } else {
      finishSave(index, retailer.retailer_logo);
    }
  };

  const finishSave = async (index, logoUrl) => {
    const newData = [...data];
    newData[index].isEditing = false;
    newData[index].retailer_logo = logoUrl;
    setData(newData);
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/admin/ups-retailer`, { userid, organisation, retailer: newData[index] });
      toast({
        title: "Success",
        description: "Retailer updated successfully",
        status: "success",
        duration: 1500,
        isClosable: true,
      });
      await fetchRetailerAccess();
    } catch (error) {
      toast({
        title: "Failed!",
        description: "Technical Error Occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setUploadingIndex(null);
    }
  };

  const handleChange = (index, field, value) => {
    const newData = [...data];
    if (field === "logoFile") {
      newData[index].logoFile = value;
      newData[index].retailer_logo = value ? URL.createObjectURL(value) : newData[index].retailer_logo;
    } else {
      newData[index][field] = value;
    }
    setData(newData);
  };

  return (
    <Table variant="striped" size="sm" colorScheme="gray" borderColor="gray.200" borderWidth="2px" borderStyle="solid">
      <Thead>
        <Tr>
          <Th>Retailer Code</Th>
          <Th>Name</Th>
          <Th>Logo</Th>
          <Th>Active</Th>
          <Th style={{ width: "150px" }}>
            <Button rightIcon={<FaPlus />} onClick={addRow} ml={2} colorScheme="customRed" variant="outline" size="xs">
              Add
            </Button>
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {data.map((row, index) => (
          <Tr key={row.id}>
            <Td>{row.isEditing ? <Input type="text" width="150px" value={row.retailercode} onChange={(e) => handleChange(index, "retailercode", e.target.value)} /> : row.retailercode}</Td>
            <Td>{row.isEditing ? <Input type="text" width="150px" value={row.retailer_name} onChange={(e) => handleChange(index, "retailer_name", e.target.value)} /> : row.retailer_name}</Td>
            <Td text-align="center" style={{ maxWidth: "100px", overflow: "hidden" }}>
              {row.isEditing ? (
                 <Flex align="center" alignItems="center" justifyContent="space-between">
                 <IconButton
                   icon={<FaUpload />}
                   onClick={() => document.getElementById(`fileInput-${index}`).click()}
                   size="sm"
                   flexShrink={0}
                 />
                 <Input
                   id={`fileInput-${index}`}
                   type="file"
                   accept="image/*"
                   onChange={(e) => handleChange(index, "logoFile", e.target.files[0])}
                   display="none"
                 />
                 {row.logoFile && (
                   <Box boxShadow="sm" p="2" rounded="md" bg="gray.50" flexGrow={1}>
                     <Image src={row.retailer_logo} alt="Preview" width="75%" height="50px" objectFit="contain" rounded="md" />
                   </Box>
                 )}
               </Flex>
              ) : (
                <Box boxShadow="sm" p="2" rounded="md" bg="gray.50" width="100%">
                  <Image src={row.retailer_logo} alt="Retailer Logo" width="50%" height="50px" objectFit="contain" rounded="md" />
                </Box>
              )}
            </Td>
            <Td>{row.isEditing ? <Switch isChecked={row.activeyn} onChange={(e) => handleChange(index, "activeyn", e.target.checked)} colorScheme="green" /> : <Switch isChecked={row.activeyn} isReadOnly colorScheme={row.activeyn ? "green" : "red"} />}</Td>
            <Td>{row.isEditing ? <IconButton icon={uploadingIndex === index ? <Spinner size="sm" /> : <FaSave />} onClick={() => saveRow(index)} ml={2} size="sm" isDisabled={uploadingIndex !== null} /> : <IconButton icon={<FaEdit />} onClick={() => editRow(index)} ml={2} size="sm" />}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export default Retailers;
