import React, { useState, useEffect } from "react";
import { Box, Button, Input, Select, Table, Thead, Tbody, Tr, Th, Td, Divider, useColorModeValue, useTheme } from "@chakra-ui/react";
import { FaPlus, FaTrash } from "react-icons/fa";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CaptureStep1 = ({ articleData, stockItem, onSubmit }) => {
  const theme = useTheme();
  const [articles, setArticles] = useState([...articleData]);
  const [totals, setTotals] = useState({ costIncl: 0, costExcl: 0, vat: 0, quantity: 0 });
  const [newArticle, setNewArticle] = useState({
    articleCode: "",
    articleName: "",
    packSize: 0,
    quantity: 0,
    costExcl: 0,
    costIncl:0,
    vat:0,
    bbDate:""
  });

  useEffect(() => {
    onSubmit(articles,totals);
  }, [articles, onSubmit]);

  useEffect(() => {
    calculateTotalCases();
  }, [articles]);

  const handleArticleCode = (index, value) => {
    let field = "articleCode";
    let updatedArticles = articles.map((article, i) => {
      if (i === index) {
        return {
          ...article,
          [field]: value,
          articleName: `${value} description`,
          packSize: 12,
          costExcl: 99.99,
          vat: 4.59,
          quantity: 1,
          costIncl: 104.58,
        };
      }
      return article;
    });

    setArticles(updatedArticles);
  };
  const handleQuantity = (index, value) => {
    let field = "quantity";
    if (value == "") {
      let updatedArticles = articles.map((article, i) => (i === index ? { ...article, [field]: value } : article));
      setArticles(updatedArticles);
    } else {
      let updatedArticles = articles.map((article, i) => {
        if (i === index) {
          article.costExcl = 99.99;
          return {
            ...article,
            [field]: value,
            costExcl: parseFloat(article.costExcl * value).toFixed(3),
            vat: parseFloat(article.costExcl * value * 0.14).toFixed(3),
            costIncl: value > 0 ? parseFloat(article.costExcl * 0.14 + article.costExcl * value).toFixed(3) : 0,
          };
        }
        return article;
      });
      setArticles(updatedArticles);
    }
  };
  const handleArticlesTdChange = (key, value, index) => {
    const updatedArticles = [...articles];
    updatedArticles[index][key] = value;
    setArticles(updatedArticles);
  };
  const handleRemove = (index) => {
    setArticles((currentArticles) => {
      return currentArticles.filter((_, i) => i !== index);
    });
  };
  const addArticle = () => {
    setArticles([...articles, { ...newArticle }]);
    setNewArticle({
      articleCode: "",
      articleName: "",
      costExcl: 0,
      costIncl: 0,
      packSize: 0,
      quantity: 0,
      vat: 0,
      bbDate: "",
    });
  };

  const calculateTotalCases = () => {
    let tots = { costIncl: 0, costExcl: 0, vat: 0, quantity: 0 };
    for (let i = 0; i < articles.length; i++) {
      tots.quantity += parseInt(articles[i].quantity) || 0;
      tots.costExcl += parseFloat(articles[i].costExcl) || 0;
      tots.vat += parseFloat(articles[i].vat) || 0;
      tots.costIncl += parseFloat(articles[i].costIncl) || 0;
    }
    tots.vat = parseFloat(tots.vat).toFixed(3);
    tots.costExcl = parseFloat(tots.costExcl).toFixed(3);
    tots.costIncl = parseFloat(tots.costIncl).toFixed(3);
    setTotals(tots);
  };

  CaptureStep1.verify = async () => {
    return true;
  };

  return (
    <Box p={4} borderWidth={1} borderRadius="md" bg="white">
      <Box mb={4}>
        <Table variant="striped" size="sm" colorScheme="gray" borderColor="gray.200" borderWidth="2px" borderStyle="solid">
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>Supplier Ref Nr</Th>
              <Th>Date</Th>
              <Th>Supplier</Th>
              <Th>Total Cases</Th>
              <Th>Source Distributor</Th>
              <Th>Destination Distributor</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr key={1}>
              <Td>{stockItem.id}</Td>
              <Td>{stockItem.supplierRef}</Td>
              <Td>{stockItem.date}</Td>
              <Td>{stockItem.supplier}</Td>
              <Td>{stockItem.totalCases}</Td>
              <Td>{stockItem.fromWH}</Td>
              <Td>{stockItem.toWH}</Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
      <Divider />
      <Box mb={4}>
        <Table variant="striped" size="sm" colorScheme="gray" borderColor="gray.200" borderWidth="2px" borderStyle="solid">
          <Thead>
            <Tr>
              <Th>Article Number</Th>
              <Th>Article Name</Th>
              <Th>PackSize</Th>
              <Th>Quantity</Th>
              <Th>Cost (excl)</Th>
              <Th>vat</Th>
              <Th>Cost (incl)</Th>
              <Th>BB Date</Th>
              <Th>
                <Button rightIcon={<FaPlus />} onClick={addArticle} ml={2} size="xs" colorScheme="customRed" variant="outline">
                  Add
                </Button>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {articles.map((article, index) => (
              <Tr key={index}>
                <Td>
                  <Select value={article.articleCode} onChange={(e) => handleArticleCode(index, e.target.value)} placeholder="Select Article">
                    <option value="ALLA">ALLA</option>
                    <option value="ALLB">ALLB</option>
                    <option value="ALLC">ALLC</option>
                    <option value="ALLD">ALLD</option>
                  </Select>
                </Td>
                <Td>
                  <Input value={article.articleName} onChange={(e) => handleArticlesTdChange("articleName", e.target.value, index)} />
                </Td>

                <Td>
                  <Input value={article.packSize} onChange={(e) => handleArticlesTdChange("packSize", e.target.value, index)} />
                </Td>
                <Td>
                  <Input type="number" value={article.quantity} onChange={(e) => handleQuantity(index, e.target.value)} errorBorderColor="crimson" />
                </Td>
                <Td>
                  <Input type="number" value={article.costExcl} onChange={(e) => handleArticlesTdChange("costExcl", e.target.value, index)} errorBorderColor="crimson" />
                </Td>
                <Td>
                  <Input type="number" value={article.vat} onChange={(e) => handleArticlesTdChange("vat", e.target.value, index)} errorBorderColor="crimson" />
                </Td>
                <Td>
                  <Input type="number" value={article.costIncl} onChange={(e) => handleArticlesTdChange("costIncl", e.target.value, index)} />
                </Td>
                <Td>
                  <DatePicker placeholderText="Select Order Date" selected={article.bbDate} onChange={(e) => handleArticlesTdChange("bbDate", e.toLocaleDateString("en-CA", { year: "numeric", month: "2-digit", day: "2-digit" }), index)} dateFormat="yyyy/MM/dd" customInput={<Input width="200px" />} />
                </Td>
                <Td>
                  <Button rightIcon={<FaTrash />} onClick={() => handleRemove(index)} ml={2} size="xs" colorScheme="customRed" variant="outline">
                    Remove
                  </Button>
                </Td>
              </Tr>
            ))}
            <Tr borderTop={"2px solid black"}>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td style={{ fontWeight: "bold" }} textAlign="right">
                {totals.quantity ? totals.quantity : ""}
              </Td>
              <Td style={{ fontWeight: "bold" }} textAlign="right">
                {totals.costExcl > 0 ? totals.costExcl : ""}
              </Td>
              <Td style={{ fontWeight: "bold" }} textAlign="right">
                {totals.vat > 0 ? totals.vat : ""}
              </Td>
              <Td style={{ fontWeight: "bold" }} textAlign="right">
                {totals.costIncl > 0 ? totals.costIncl : ""}
              </Td>
              <Td></Td>
              <Td></Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default CaptureStep1;
