import React, { useState } from 'react';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';

const SubNav = ({ data, ...props }) => {
  const [tabIndex, setTabIndex] = useState(0);  // State to keep track of the active tab index

  const handleTabsChange = (index) => {
    setTabIndex(index);
  };

  return (
    <Tabs isFitted variant="enclosed" colorScheme="customRed" onChange={handleTabsChange} {...props}
    sx={{
      bg: 'rgba(245, 245, 245, 0.5)', 
      padding: 2,
      borderRadius: 'lg',
      boxShadow: '6px 6px 12px #bebebe, -6px -6px 12px #ffffff' 
    }}
    >
      <TabList mb="0.5em">
        {data.map((tab, index) => (
          <Tab key={index}
          >{tab.label}</Tab>
        ))}
      </TabList>
      <TabPanels>
        {data.map((tab, index) => (
          <TabPanel p={4} key={index}>
            {index === tabIndex ? tab.component : null}  
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};

export default SubNav;
