import React from "react";
import { Box } from "@chakra-ui/react";

import Users from "./components/Users";
import Suppliers from "./components/Suppliers";
import Retailers from "./components/Retailers";
import Articles from "./components/Articles";
import Stores from "./components/Stores";
import Warehouses from "./components/Warehouses";
import Distributors from "./components/Distributors";

import SubNav from "../../components/SubNav";

import { useSelection } from "../../wrappers/SelectionContext";

const Admin = () => {
  const { userId, selectedOrganisation, selectedSupplier, selectedRetailer, selectedWarehouse, selectedDistributor, fetchWarehouseAccess, fetchDistributorAccess, fetchSupplierAccess, fetchRetailerAccess } = useSelection();
  const tabData = [
    {
      label: "Users",
      component: <Users userid={userId} organisation={selectedOrganisation} supplier={selectedSupplier} retailer={selectedRetailer} />,
    },
    {
      label: "Retailers",
      component: <Retailers userid={userId} organisation={selectedOrganisation} supplier={selectedSupplier} retailer={selectedRetailer} fetchRetailerAccess={fetchRetailerAccess} />,
    },
    {
      label: "Suppliers",
      component: <Suppliers userid={userId} organisation={selectedOrganisation} supplier={selectedSupplier} retailer={selectedRetailer} fetchSupplierAccess={fetchSupplierAccess} />,
    },
    {
      label: "Warehouses",
      component: <Warehouses userid={userId} organisation={selectedOrganisation} supplier={selectedSupplier} retailer={selectedRetailer} fetchWarehouseAccess={fetchWarehouseAccess} />,
    },
    {
      label: "Distributors",
      component: <Distributors userid={userId} organisation={selectedOrganisation} supplier={selectedSupplier} retailer={selectedRetailer} fetchDistributorAccess={fetchDistributorAccess} />,
    },
    {
      label: "Articles",
      component: <Articles userid={userId} organisation={selectedOrganisation} supplier={selectedSupplier} retailer={selectedRetailer} />,
    },
    {
      label: "Stores",
      component: <Stores userid={userId} organisation={selectedOrganisation} supplier={selectedSupplier} retailer={selectedRetailer} selectedWarehouse={selectedWarehouse} selectedDistributor={selectedDistributor} />,
    },
  ];

  return (
      <SubNav mt={12} data={tabData} />
  );
};

export default Admin;
