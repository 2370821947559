import React, { useState } from "react";
import { Box, Button, useTheme } from "@chakra-ui/react";

import CaptureStep1 from "./CaptureStep1";
import CaptureStep2 from "./CaptureStep2";
import Breadcrumb from "../../../../../components/Breadcrumb";

const steps = [
  { title: "Articles", description: "" },
  { title: "Sumbit", description: "" },
];

const Capture = ({ item, index, onSubmit, onCancel }) => {
  const theme = useTheme();
  const [articleData, setArticleData] = useState(item ? item.articles : []);
  const [totals, setTotals] = useState({ costIncl: 0, costExcl: 0, vat: 0, quantity: 0 });
  const [stockItem, setStockItem] = useState(item ? { id: item.id, supplierRef: item.supplierRef, date: item.date, supplier: item.supplier, totalCases: item.totalCases } : {});

  const handleStep1Submit = (articlesData, totalsData) => {
    setArticleData(articlesData);
    setTotals(totalsData);
  };

  const handleNextStep = async (currentStep) => {
    if (currentStep === 0) {
      const result = await CaptureStep1.verify(handleStep1Submit);
      if (!result) return false;
    }
    return true;
  };
  const handleStep2Submit = (newArticles,newTotals) => {
    onSubmit(newArticles,newTotals, index);
  };

  const stepContents = [<CaptureStep1 articleData={articleData} stockItem={stockItem} onSubmit={handleStep1Submit} />,
     <CaptureStep2 articleData={articleData} stockItem={stockItem} totalsData={totals} onSubmit={handleStep2Submit} />];

  return (
    <Box>
      <Breadcrumb steps={steps} stepContents={stepContents} onNext={handleNextStep} />
      <Button onClick={onCancel} mt={4} colorScheme="customRed" variant="outline">
        Cancel
      </Button>
    </Box>
  );
};

export default Capture;
