// ChakraWrapper.js
import React from "react";
import { ChakraProvider, extendTheme, Spinner } from "@chakra-ui/react";
import { FaPlus, FaFileUpload, FaSave } from "react-icons/fa";

const customTheme = extendTheme({
  components: {
    Input: {
      variants: {
        outline: (props) => ({
          field: {
            borderColor: props.colorMode === "dark" ? "black" : "black",
            _hover: {
              borderColor: "gray",
            },
            _focus: {
              borderColor: "customRed.800",
              boxShadow: `0 0 0 1px ${props.theme.colors.customRed[800]}`,
            },
          },
        }),
      },
    },
    Select: {
      variants: {
        outline: (props) => ({
          field: {
            borderColor: props.colorMode === "dark" ? "black" : "black",
            _hover: {
              borderColor: "gray",
            },
            _focus: {
              borderColor: "customRed.800",
              boxShadow: `0 0 0 1px ${props.theme.colors.customRed[800]}`,
            },
          },
        }),
      },
    },
  },
  colors: {
    customRed: {
      50: "#fbe4e0", // Lightest shade
      100: "#f5c1b8", // Lighter shade
      200: "#ef9d8f", // Light shade
      300: "#e97966", // Light normal
      400: "#e3563d", // Normal shade
      500: "#dd3314", // Base color, adjusted to fit on scale
      600: "#b02811", // Darker than base
      700: "#84200e", // Dark shade
      800: "#57180a", // Darker shade
      900: "#2b0c05", // Darkest shade
    },
    primary1: "#707070",
    primary2: "#fff",
    secondary1: "#A0A0A0",
    secondary1Light: "#A0A0A0",
    secondary2: "#93291E",
    tertiary1: "#fceabb",
    tertiary2: "#ffe5a3",
    tertiary2Light: "rgba(255, 229, 163, 0.7)",
    white: "#FFF",
    black: "#000",

    theadButton_bg: "white",
    theadButton_text: "black",
    theadButton_border: "white",

    tbodyButton_bg: "white",
    tbodyButton_text: "black",
    tbodyButton_border: "white",

    theadButton_bg_hover: "gray",
    theadButton_text_hover: "black",
    theadButton_border_hover: "gray",

    tbodyButton_bg_hover: "gray",
    tbodyButton_text_hover: "black",
    tbodyButton_border_hover: "gray",

    gradients: {
      primary: "linear(to-r, #58b6c0, #04aec1)",
      secondary: "linear(to-r, #04aec1, #58b6c0)",
    },
  },
});
const ChakraWrapper = ({ children }) => {
  return <ChakraProvider theme={customTheme}>{children}</ChakraProvider>;
};

export default ChakraWrapper;
