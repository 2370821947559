import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
// import { getDownloadURL, ref } from "firebase/storage";
// import { storage } from "../tools/firebase";

const SelectionContext = createContext();

export const useSelection = () => useContext(SelectionContext);

export const SelectionProvider = ({ children }) => {
  const [selectedRetailer, setSelectedRetailer] = useState("");
  const [retailers, setRetailers] = useState([
    { value: "PNP", text: "PnP" },
    { value: "CHK", text: "Checkers" },
    { value: "DIS", text: "Dischem" },
    { value: "CLI", text: "Clicks" },
    { value: "MAK", text: "Makro" },
    { value: "SPA", text: "Spar" },
    { value: "IND", text: "Independant" },
  ]);
  const [availableRetailers, setAvailableRetailers] = useState({});
  const [selectedSupplier, setSelectedSupplier] = useState("");

  const [selectedWarehouse, setSelectedWarehouse] = useState("GT");
  const [availableWarehouses, setAvailableWarehouses] = useState({ GT: true, KZN: true, WC: true, EC: true, NW: true });
  const [user_warehouse_access, setUser_warehouse_access] = useState([]);

  const [selectedDistributor, setSelectedDistributor] = useState("DistA");
  const [availableDistributors, setAvailableDistributors] = useState({ DistA: true, DistB: true, DistC: true, DistD: true, DistE: true });
  const [user_distributor_access, setUser_distributor_access] = useState([]);

  const [selectedOrganisation, setSelectedOrganisation] = useState("chainStoreSolutions");
  const [organisationAccess, setOrganisationAccess] = useState([]);

  const [user_supplier_access, setUser_supplier_access] = useState([]);
  const [loading, setLoading] = useState(true);

  const [organisationCode, setOrganisationCode] = useState(null);
  const [supplierCode, setSuppplierCode] = useState(null);
  const [retailerCode, setRetailerCode] = useState(null);
  const [userId, setUserId] = useState(null);

  const fetchUserAccess = async ({ userid, organisationcode, supppliercode, retailercode }) => {
    if (userid == null) {
      return;
    }
    try {
      setLoading(true);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/get-selectionContext`, { userid, organisationcode });
      const { status, data, title } = response.data;
      const { suppliers, retailers, warehouses, distributors, organisations } = data;
      setOrganisationCode(organisationcode);
      setRetailerCode(retailercode);
      setSuppplierCode(supppliercode);
      setUserId(userid);

      let suppliers_temp = [];
      for (let i = 0; i < suppliers.length; i++) {
        let temp = { value: suppliers[i].suppliercode, text: suppliers[i].supplier_name };
        suppliers_temp.push(temp);
      }
      setUser_supplier_access(suppliers_temp);
      setSelectedSupplier(suppliers[0].suppliercode);

      let retailer_temp = {};
      for (let i = 0; i < retailers.length; i++) {
        retailer_temp[retailers[i].retailercode] = true;
      }
      setSelectedRetailer(retailers[0].retailercode);
      setAvailableRetailers(retailer_temp);

      let dist_temp = {};
      let distAccess_temp = [];
      for (let i = 0; i < distributors.lngth; i++) {
        let { distributorcode, dist_name } = distributors[i];
        dist_temp[distributorcode] = true;
        distAccess_temp.push({ value: distributorcode, text: dist_name });
      }
      setSelectedDistributor(distributors[0].distributorcode);
      setAvailableDistributors(dist_temp);
      setUser_distributor_access(distAccess_temp);

      let warehouses_temp = {};
      let wh_access = [];
      for (let i = 0; i < warehouses.length; i++) {
        warehouses_temp[warehouses[i].warehousecode] = true;
        wh_access.push({ value: warehouses[i].warehousecode, text: warehouses[i].wh_name });
      }
      setAvailableWarehouses(warehouses_temp);
      setUser_warehouse_access(wh_access);
      setSelectedWarehouse(warehouses[0].warehousecode);

      let orgs_temp = [];
      for (let i = 0; i < organisations.length; i++) {
        orgs_temp.push({ value: organisations[i].organisationcode, text: organisations[i].organisation_name });
      }
      setSelectedOrganisation(organisations[0].organisationcode);
      setOrganisationAccess(orgs_temp);
    } catch (error) {
      console.error("Error fetching user access:", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchRetailerAccess = async () => {
    if (userId == null) {
      return;
    }
    try {
      setLoading(true);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/get-retailers`, { userid: userId, organisation: selectedOrganisation, source: "selectionContext" });
      const { status, retailers, title } = response.data;
      let retailer_temp = {};
      for (let i = 0; i < retailers.length; i++) {
        retailer_temp[retailers[i].retailercode] = true;
      }
      setSelectedRetailer("PNP");
      setAvailableRetailers(retailer_temp);
    } catch (error) {
      console.error("Error fetching user access:", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchWarehouseAccess = async () => {
    if (userId == null) {
      return;
    }
    try {
      setLoading(true);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/get-warehouses`, { userid: userId, organisation: selectedOrganisation, source: "selectionContext" });
      const { status, warehouses, title } = response.data;
      let warehouses_temp = {};
      let wh_access = [];
      for (let i = 0; i < warehouses.length; i++) {
        warehouses_temp[warehouses[i].warehousecode] = true;
        wh_access.push({ value: warehouses[i].warehousecode, text: warehouses[i].wh_name });
      }
      setAvailableWarehouses(warehouses_temp);
      setUser_warehouse_access(wh_access);
      setSelectedWarehouse(warehouses[0].warehousecode);
    } catch (error) {
      console.error("Error fetching user access:", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchDistributorAccess = async () => {
    if (userId == null && organisationCode == null) {
      return;
    }
    try {
      setLoading(true);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/get-distributors`, { userid: userId, organisation: selectedOrganisation, source: "selectionContext" });
      const { status, data, title } = response.data;
      const distributors = data.distributors;
      let dist_temp = {};
      let distAccess_temp = [];
      for (let i = 0; i < distributors.length; i++) {
        let { distributorcode, dist_name } = distributors[i];
        dist_temp[distributorcode] = true;
        distAccess_temp.push({ value: distributorcode, text: dist_name });
      }
      setSelectedDistributor(distributors[0].distributorcode);
      setAvailableDistributors(dist_temp);
      setUser_distributor_access(distAccess_temp);
    } catch (error) {
      console.error("Error fetching dists:", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchSupplierAccess = async () => {
    if (userId == null && organisationCode == null) {
      return;
    }
    try {
      setLoading(true);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/get-suppliers`, { userid: userId, organisation: selectedOrganisation, source: "selectionContext" });
      const { status, suppliers, title } = response.data;

      let suppliers_temp = [];
      for (let i = 0; i < suppliers.length; i++) {
        let temp = { value: suppliers[i].suppliercode, text: suppliers[i].supplier_name };
        suppliers_temp.push(temp);
      }
      setUser_supplier_access(suppliers_temp);
      setSelectedSupplier(suppliers[0].suppliercode);
    } catch (error) {
      console.error("Error fetching dists:", error);
    } finally {
      setLoading(false);
    }
  };

  const resetContext = () => {
    setUser_supplier_access([]);
    setSelectedRetailer("");
    setSelectedSupplier("");
    setSelectedWarehouse("");
    setLoading(false);
    setAvailableRetailers({});
    setAvailableWarehouses({});
    setUser_warehouse_access([]);
  };

  useEffect(() => {
    setAvailableRetailers({});
    fetchRetailerAccess();
  }, [selectedSupplier, selectedOrganisation]);

  useEffect(() => {
    fetchDistributorAccess();
  }, [selectedWarehouse, selectedRetailer, selectedOrganisation, selectedDistributor]);

  useEffect(() => {
    fetchWarehouseAccess();
  }, [selectedOrganisation]);

  return (
    <SelectionContext.Provider
      value={{
        selectedRetailer,
        setSelectedRetailer,
        selectedSupplier,
        setSelectedSupplier,
        retailers,
        user_supplier_access,
        loading,
        fetchUserAccess,
        resetContext,
        availableRetailers,
        selectedWarehouse,
        setSelectedWarehouse,
        user_warehouse_access,
        selectedDistributor,
        setSelectedDistributor,
        user_distributor_access,

        userId,
        organisationCode,
        retailerCode,
        supplierCode,

        selectedOrganisation,
        setSelectedOrganisation,
        organisationAccess,

        fetchWarehouseAccess,
        fetchDistributorAccess,
        fetchSupplierAccess,
        fetchRetailerAccess,
      }}
    >
      {children}
    </SelectionContext.Provider>
  );
};
