import { useState } from "react";
import { Button, Spinner, useToast } from "@chakra-ui/react";
import { FaUpload } from "react-icons/fa";
import axios from "axios";

const FileUpload = ({ sender, organisation, retailer = null, warehouse = null, distributor = null, supplier = null,onsave }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const toast = useToast();

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleButtonClick = () => {
    if (!selectedFile) {
      document.getElementById("fileInput").click();
    } else {
      handleFileUpload();
    }
  };

  const handleFileUpload = async () => {
    setIsUploading(true);

    const formData = new FormData();
    formData.append("xlsxFile", selectedFile);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/upload_${sender}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      const { status, title, description } = response.data;
      toast({
        title: title,
        description: description,
        status: status,
        duration: 2000,
        isClosable: true,
      });
      onsave();
      setSelectedFile(null);
    } catch (error) {
      const errMsg = error.response?.data?.data?.error || "Technical Error Occurred";
      toast({
        title: "File Upload Failed",
        description: errMsg,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} style={{ display: "none" }} id="fileInput" />
      <Button mt={4} leftIcon={isUploading ? <Spinner /> : <FaUpload />} onClick={handleButtonClick} isLoading={isUploading} loadingText="Uploading" cursor="pointer" colorScheme="customRed" variant="outline">
        {selectedFile ? `Upload ${selectedFile.name}` : "Select File"}
      </Button>
    </div>
  );
};

export default FileUpload;
