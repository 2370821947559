import React, { useEffect, useState } from "react";
import { Button, Menu, MenuButton, MenuList, MenuItem, Image, Box, useTheme } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useSelection } from "../wrappers/SelectionContext";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../tools/firebase";

const DistributorNavigation = () => {
  const theme = useTheme();
  const { selectedDistributor, setSelectedDistributor, user_distributor_access } = useSelection();
  const [logos, setLogos] = useState({});

  const handleDistributorChange = (distributor) => {
    setSelectedDistributor(distributor);
  };

  useEffect(() => {
    const fetchLogos = async () => {
      const logoUrls = {};
      for (const distributor of user_distributor_access) {
        try {
          const logoRef = ref(storage, `distributor_logos/${distributor.value}`);
          const url = await getDownloadURL(logoRef);
          logoUrls[distributor.value] = url;
        } catch (error) {
          console.error(`missing distributor logo for: ${distributor.value}`);
          const defaultLogoRef = ref(storage, `logos/blank.jpg`);
          try {
            const defaultUrl = await getDownloadURL(defaultLogoRef);
            logoUrls[distributor.value] = defaultUrl;
          } catch (defaultError) {
            console.error("Error fetching default logo:", defaultError);
          }
        }
      }
      setLogos(logoUrls);
    };

    if (user_distributor_access.length > 0) {
      fetchLogos();
    }
  }, [user_distributor_access]);

  return (
    <Box display="flex" alignItems="center" m={1} width="calc(100% - 8px)" mx="4px">
      <Menu>
        <MenuButton as={Button} rightIcon={<ChevronDownIcon />} bg={"white"} _hover={{ bg: `${theme.colors.secondary1} !important`, color: "black !important" }} color="black" maxWidth="100%" minWidth="100%" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" px={1} justifyContent="space-between">
          {selectedDistributor}
        </MenuButton>
        <MenuList bg={"white"} color="white" maxWidth="100%" maxHeight="500px" overflowY="auto">
          {user_distributor_access.map((distributor) => (
            <MenuItem key={distributor.value} onClick={() => handleDistributorChange(distributor.value)} bg="white" _hover={{ bg: `${theme.colors.secondary2} !important`, color: "white !important" }} color="black" maxWidth="100%" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" minH="40px">
              {logos[distributor.value] && <Image boxSize="2rem" borderRadius="full" src={logos[distributor.value]} alt={distributor.text} mr="12px" />}
              <span>{distributor.text}</span>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  );
};

export default DistributorNavigation;
