import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Thead, Tbody, Tr, Th, Td, Button, useTheme, IconButton, Input, Switch, useToast, Spinner } from "@chakra-ui/react";
import { FaPlus, FaEdit, FaSave } from "react-icons/fa";

const Articles = ({ userid, organisation, supplier, retailer }) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const [data, setData] = useState([]);
  const [uploadingIndex, setUploadingIndex] = useState(null);

  const fetchArticles = async () => {
    try {
      setLoading(true);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/get-articles`, { userid, organisation, supplier, retailer });
      const { status, title, message, data } = response.data;
      console.log({ status, title, message, data });
      setLoading(false);
      setData(data);
      toast({
        title: title,
        description: message,
        status: status,
        duration: 1500,
        isClosable: true,
      });
    } catch (error) {
      const errMsg = error.response?.data?.data?.error || "Technical Error Occurred";
      setLoading(false);
      toast({
        title: "Failed!",
        description: errMsg,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchArticles();
  }, [organisation, supplier, retailer]);

  const addRow = () => {
    setData([
      ...data,
      {
        id: data.length + 1,
        retailer_articlecode: "",
        supplier_articlecode: "",
        article_desc: "",
        article_cost: 0,
        article_barcode: "",
        article_packsize: 0,
        article_vendor: "",
        article_length: "",article_breadth: "",article_height: "",article_kgs: "",article_ti: "",article_hi: "",article_cases: "",
        activeyn: true,
        isEditing: true,
      },
    ]);
  };

  const editRow = (index) => {
    const newData = [...data];
    newData[index].isEditing = true;
    setData(newData);
  };

  const saveRow = async (index) => {
    try {
      const newData = [...data];
      newData[index].isEditing = false;
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/ups-article`, { userid, organisation, supplier, retailer, article: newData[index] });
      const { status, title, message } = response.data;
      toast({
        title: title,
        description: message,
        status: status,
        duration: 1500,
        isClosable: true,
      });
      setData(newData);
    } catch (error) {
      const errMsg = error.response?.data?.data?.error || "Technical Error Occurred";
      toast({
        title: "Failed!",
        description: errMsg,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleChange = (index, field, value) => {
    const newData = [...data];
    newData[index][field] = value;
    setData(newData);
  };

  return (
    <Table variant="striped" size="sm" colorScheme="gray" borderColor="gray.200" borderWidth="2px" borderStyle="solid">
      <Thead>
        <Tr>
          <Th>Retailer Article Code</Th>
          <Th>Supplier Article Code</Th>
          <Th>Article Name</Th>
          <Th>Article Cost(excl)</Th>
          <Th>Article Barcode</Th>
          <Th>Article PackSize</Th>
          <Th>Article Vendor Acc</Th>
          <Th>L</Th>
          <Th>B</Th>
          <Th>H</Th>
          <Th>Actual kgs</Th>
          <Th>ti</Th>
          <Th>hi</Th>
          <Th>Cases/Pallet</Th>
          <Th>Active</Th>
          <Th style={{ width: "100px" }}>
            <Button rightIcon={<FaPlus />} onClick={addRow} ml={2} colorScheme="customRed" variant="outline" size="xs">
              Add
            </Button>
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {data.map((row, index) => (
          <Tr key={row.id}>
            <Td>{row.isEditing ? <Input type="text" width="80px" value={row.retailer_articlecode} onChange={(e) => handleChange(index, "retailer_articlecode", e.target.value)} /> : row.retailer_articlecode}</Td>
            <Td>{row.isEditing ? <Input type="text" width="80px" value={row.supplier_articlecode} onChange={(e) => handleChange(index, "supplier_articlecode", e.target.value)} /> : row.supplier_articlecode}</Td>
            <Td>{row.isEditing ? <Input type="text" width="80px" value={row.article_desc} onChange={(e) => handleChange(index, "article_desc", e.target.value)} /> : row.article_desc}</Td>
            <Td>{row.isEditing ? <Input type="text" width="80px" value={row.article_cost} onChange={(e) => handleChange(index, "article_cost", e.target.value)} /> : row.article_cost}</Td>
            <Td>{row.isEditing ? <Input type="text" width="80px" value={row.article_barcode} onChange={(e) => handleChange(index, "article_barcode", e.target.value)} /> : row.article_barcode}</Td>
            <Td>{row.isEditing ? <Input type="text" width="60px" value={row.article_packsize} onChange={(e) => handleChange(index, "article_packsize", e.target.value)} /> : row.article_packsize}</Td>
            <Td>{row.isEditing ? <Input type="text" width="80px" value={row.article_vendor} onChange={(e) => handleChange(index, "article_vendor", e.target.value)} /> : row.article_vendor}</Td>
           
            <Td>{row.isEditing ? <Input type="text" width="60px" value={row.article_length} onChange={(e) => handleChange(index, "article_length", e.target.value)} /> : row.article_length}</Td>
            <Td>{row.isEditing ? <Input type="text" width="60px" value={row.article_breadth} onChange={(e) => handleChange(index, "article_breadth", e.target.value)} /> : row.article_breadth}</Td>
            <Td>{row.isEditing ? <Input type="text" width="60px" value={row.article_height} onChange={(e) => handleChange(index, "article_height", e.target.value)} /> : row.article_height}</Td>
            <Td>{row.isEditing ? <Input type="text" width="60px" value={row.article_kgs} onChange={(e) => handleChange(index, "article_kgs", e.target.value)} /> : row.article_kgs}</Td>
            <Td>{row.isEditing ? <Input type="text" width="60px" value={row.article_ti} onChange={(e) => handleChange(index, "article_ti", e.target.value)} /> : row.article_ti}</Td>
            <Td>{row.isEditing ? <Input type="text" width="60px" value={row.article_hi} onChange={(e) => handleChange(index, "article_hi", e.target.value)} /> : row.article_hi}</Td>
            <Td>{row.isEditing ? <Input type="text" width="60px" value={row.article_cases} onChange={(e) => handleChange(index, "article_cases", e.target.value)} /> : row.article_cases}</Td>
           
            <Td>{row.isEditing ? <Switch isChecked={row.activeyn} onChange={(e) => handleChange(index, "activeyn", e.target.checked)} colorScheme="green" /> : <Switch isChecked={row.activeyn} isReadOnly colorScheme={row.activeyn ? "green" : "red"} />}</Td>
            <Td>{row.isEditing ? <IconButton icon={uploadingIndex === index ? <Spinner size="sm" /> : <FaSave />} onClick={() => saveRow(index)} ml={2} size="sm" isDisabled={uploadingIndex !== null} /> : <IconButton icon={<FaEdit />} onClick={() => editRow(index)} ml={2} size="sm" />}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export default Articles;
