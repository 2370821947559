import React, { useState } from "react";
import { Box, Button, ButtonGroup, FormControl, FormLabel, IconButton, Input, Popover, PopoverArrow, PopoverCloseButton, PopoverContent, PopoverTrigger, Stack, Textarea, useDisclosure, Td } from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import FocusLock from "react-focus-lock";

const TextAreaInput = React.forwardRef((props, ref) => {
  return (
    <FormControl>
      <FormLabel htmlFor={props.id}>{props.label}</FormLabel>
      <Textarea ref={ref} id={props.id} {...props} />
    </FormControl>
  );
});

const CommentForm = ({ firstFieldRef, onCancel, onSave, defaultValue }) => {
  const [comment, setComment] = useState(defaultValue);

  const handleSave = () => {
    onSave(comment);
  };

  return (
    <Stack spacing={4}>
      <TextAreaInput label="Comment" id="comment" ref={firstFieldRef} defaultValue={defaultValue} onChange={(e) => setComment(e.target.value)} />
      <ButtonGroup display="flex" justifyContent="flex-end">
        <Button variant="outline" onClick={onCancel}>
          Cancel
        </Button>
        <Button colorScheme="customRed" onClick={handleSave}>
          Save
        </Button>
      </ButtonGroup>
    </Stack>
  );
};

const CommentPopover = ({ index, row, handle_comment }) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const firstFieldRef = React.useRef(null);

  const handleSave = (comment) => {
    handle_comment(index, comment);
    onClose();
  };

  return (
    <>
      <Popover isOpen={isOpen} initialFocusRef={firstFieldRef} onOpen={onOpen} onClose={onClose} placement="right" closeOnBlur={false}>
        <PopoverTrigger>
          <IconButton size="sm"  border="2px" borderColor="white.300" icon={<EditIcon />} />
        </PopoverTrigger>
        <PopoverContent p={5}>
          <FocusLock returnFocus persistentFocus={false}>
            <PopoverArrow />
            <PopoverCloseButton />
            <CommentForm firstFieldRef={firstFieldRef} onCancel={onClose} onSave={handleSave} defaultValue={row.debrief_comment} />
          </FocusLock>
        </PopoverContent>
      </Popover>
    </>
  );
};

export default CommentPopover;
