import React, { useState,useEffect } from "react";
import { Box, Input, Select, FormControl, FormLabel, useTheme,useToast } from "@chakra-ui/react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CaptureStep1 = ({ step1Data,organisation, retailer, onSubmit }) => {
  const theme = useTheme();
  const toast=useToast();
  const [po_number, setPoNumber] = useState(step1Data.po_number);
  const [order_date, setOrderDate] = useState(step1Data.order_date);
  const [delivery_date, setDeliveryDate] = useState(step1Data.delivery_date);
  const [store_code, setStoreCode] = useState(step1Data.store_code);
  const [store_name, setStoreName] = useState(step1Data.store_name);
  const [stores, setStores] = useState([]);
  const [errors, setErrors] = useState({ po_number: false, store_code: false });

  const handleSubmit = (event) => {
    event.preventDefault();
    //axios call to retrieve store description
    onSubmit({ po_number, order_date: order_date, delivery_date: delivery_date, store_code, store_name,
     });
  };

  const handleStoreCode = (value) => {
    setStoreCode(value);
    setStoreName(`${value} description`);
  };
  const fetchStores = async () => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/generic/get-dd-stores`, { retailer, organisation });
    if (response.data.status != "success") {
      toast({
        title: response.data.title,
        description: response.data.message,
        status: response.data.status,
        duration: 2500,
        isClosable: true,
      });
      return;
    }
    setStores(response.data.data.stores);
  };
  useEffect(() => {


    fetchStores();
  }, []);

  CaptureStep1.verify = async (submitCallback) => {
    const newErrors = {
      po_number: !po_number,
      store_code: !store_code,
    };
    setErrors(newErrors);
    if (Object.values(newErrors).some((error) => error)) {
      return false;
    }
    submitCallback({ po_number, order_date: order_date, delivery_date: delivery_date, store_code, store_name ,
    });
    return true;
  };

  return (
    <Box as="form" onSubmit={handleSubmit} p={4} borderWidth={1} borderRadius="md" bg="white">
      <FormControl mb={4} isInvalid={errors.po_number}>
        <FormLabel color={theme.colors.primary1}>PO Number</FormLabel>
        <Input width="200px" value={po_number} onChange={(e) => setPoNumber(e.target.value)} placeholder="Enter PO Number" borderColor={theme.colors.primary2} />
      </FormControl>
      <FormControl mb={4}>
        <FormLabel color={theme.colors.primary1}>Order Date</FormLabel>
        <DatePicker placeholderText="Select Order Date" selected={order_date} onChange={(date) => setOrderDate(date)} dateFormat="yyyy/MM/dd" customInput={<Input width="200px" borderColor={theme.colors.primary2} />} />
      </FormControl>
      <FormControl mb={4}>
        <FormLabel color={theme.colors.primary1}>Delivery Date</FormLabel>
        <DatePicker placeholderText="Select Delibvery Date" selected={delivery_date} onChange={(date) => setDeliveryDate(date)} dateFormat="yyyy/MM/dd" customInput={<Input width="200px" borderColor={theme.colors.primary2} />} />
      </FormControl>
      <FormControl mb={4} isInvalid={errors.store_code}>
        <FormLabel color={theme.colors.primary1}>Store Code</FormLabel>
        <Select width="200px" value={store_code} onChange={(e) => handleStoreCode(e.target.value)} placeholder="Select Store Code" borderColor={theme.colors.primary2}>
        {stores.map((store) => (
            <option key={store.id} value={store.storecode}>
              {`${store.storecode}`}
            </option>
          ))}
        </Select>
      </FormControl>
      <FormControl mb={4}>
        <FormLabel color={theme.colors.primary1}>Store Name</FormLabel>
        <Input width="200px" value={store_name} onChange={(e) => setStoreName(e.target.value)} placeholder="Store Name" borderColor={theme.colors.primary2} />
      </FormControl>
    </Box>
  );
};

export default CaptureStep1;
