import React from "react";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Table, Thead, Tbody, Tr, Th, Td, Box, Text, Divider, useTheme } from "@chakra-ui/react";

const ViewItem = ({ isOpen, onClose, item }) => {
  const theme = useTheme();
console.log(item);
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="6xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box mb={4}>
            <Table variant="striped" size="sm" colorScheme="gray" borderColor="gray.200" borderWidth="2px" borderStyle="solid">
              <Tbody>
                <Tr>
                  <Td fontWeight="bold">ID</Td>
                  <Td>{item.id}</Td>
                </Tr>
                {item.supplierRef && (
                  <Tr>
                    <Td fontWeight="bold">Supplier Reference Nr</Td>
                    <Td>{item.supplierRef}</Td>
                  </Tr>
                )}
                <Tr>
                  <Td fontWeight="bold">Date</Td>
                  <Td>{item.date}</Td>
                </Tr>
                <Tr>
                  <Td fontWeight="bold">Supplier</Td>
                  <Td>{item.supplier}</Td>
                </Tr>
                <Tr>
                  <Td fontWeight="bold">Total Cases</Td>
                  <Td>{item.totalCases}</Td>
                </Tr>
                {item.fromWH && (
                  <Tr>
                    <Td fontWeight="bold">Source(From) Distributor</Td>
                    <Td>{item.fromWH}</Td>
                  </Tr>
                )}
                {item.toWH && (
                  <Tr>
                    <Td fontWeight="bold">Destination(To) Distributor</Td>
                    <Td>{item.toWH}</Td>
                  </Tr>
                )}
                {item.comment && (
                  <Tr>
                    <Td fontWeight="bold">Comment</Td>
                    <Td>{item.comment}</Td>
                  </Tr>
                )}
                {item.costExcl && (
                  <Tr>
                    <Td fontWeight="bold">Total Cost (excl)</Td>
                    <Td>{parseFloat(item.costExcl).toFixed(3)}</Td>
                  </Tr>
                )}
                {item.vat && (
                  <Tr>
                    <Td fontWeight="bold">Total VAT</Td>
                    <Td>{parseFloat(item.vat).toFixed(3)}</Td>
                  </Tr>
                )}
                {item.costIncl && (
                  <Tr>
                    <Td fontWeight="bold">Total Cost (incl)</Td>
                    <Td>{parseFloat(item.costIncl).toFixed(3)}</Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </Box>
          <Divider />
          <Box mb={4}>
            <Table variant="striped" size="sm" colorScheme="gray" borderColor="gray.200" borderWidth="2px" borderStyle="solid">
              <Thead>
                <Tr>
                  <Th fontWeight="bold">Article Code</Th>
                  <Th fontWeight="bold">Article Name</Th>
                  <Th fontWeight="bold">Pack Size</Th>
                  <Th fontWeight="bold">Quantity</Th>
                  <Th fontWeight="bold">Cost(excl)</Th>
                  <Th fontWeight="bold">VAT</Th>
                  <Th fontWeight="bold">Cost(incl)</Th>
                  <Th fontWeight="bold">BB Date</Th>
                  <Th fontWeight="bold">Comment</Th>
                </Tr>
              </Thead>
              <Tbody>
                {item.articles.map((article, index) => (
                  <Tr key={index}>
                    <Td>{article.articleCode}</Td>
                    <Td>{article.articleName}</Td>
                    <Td>{article.packSize}</Td>
                    <Td>{article.quantity}</Td>
                    <Td>R{parseFloat(article.costExcl).toFixed(3)}</Td>
                    <Td>R{parseFloat(article.vat).toFixed(3)}</Td>
                    <Td>R{parseFloat(article.costIncl).toFixed(3)}</Td>
                    <Td>{article.bbDate || ""}</Td>
                    <Td>{article.comment || ""}</Td>
                  </Tr>
                ))}
                <Tr>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td fontWeight="bold">{item.totalCases}</Td>
                  <Td fontWeight="bold">R{parseFloat(item.costExcl).toFixed(3)}</Td>
                  <Td fontWeight="bold">R{parseFloat(item.vat).toFixed(3)}</Td>
                  <Td fontWeight="bold">R{parseFloat(item.costIncl).toFixed(3)}</Td>
                  <Td></Td>
                  <Td></Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ViewItem;
