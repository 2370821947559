import React, { useState, useEffect } from "react";
import { Box, Button, Table, Thead, Tbody, Tr, Th, Td, useTheme } from "@chakra-ui/react";

const CaptureStep3 = ({ step1Data, step2Data, onSubmit }) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null);
  const [totals, setTotals] = useState({});

  const handleSubmit = async () => {
    setLoading(true);
    try {
      // Simulate axios request
      await new Promise((resolve) => setTimeout(resolve, 2000));

      const order_code = Math.floor(100000 + Math.random() * 900000);
      const created_date = new Date().toLocaleDateString();
      const userdescription = "User"; // Replace with actual user

      const order = {
        order_code,
        po_number: step1Data.po_number,
        order_date: step1Data.order_date,
        delivery_date: step1Data.delivery_date,
        store_code: step1Data.store_code,
        store_name: step1Data.store_name,
        supplier_name: "Alettes",
        store_classification: "Gauteng",
        dist_name: "Dist A",
        cases: 45,
        cost_incl: totals.cost_incl,
        cost_excl: totals.cost_excl,
        cost_vat: totals.cost_vat,
        created_date,
        userdescription,
        order_items: [...step2Data],
      };

      setOrderDetails(order);
      onSubmit(order);

      setLoading(false);
    } catch (error) {
      console.error("An error occurred:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    calculateTotalRow();
  }, [step2Data]);

  const calculateTotalRow = () => {
    let cost_incl = 0;
    let cost_excl = 0;
    let cost_vat = 0;
    let quantity = 0;
    for (let i = 0; i < step2Data.length; i++) {
      cost_incl += parseFloat(step2Data[i].cost_incl);
      cost_excl += parseFloat(step2Data[i].cost_excl);
      cost_vat += parseFloat(step2Data[i].cost_vat);
      quantity += parseInt(step2Data[i].quantity);
    }
    cost_incl = parseFloat(cost_incl).toFixed(3);
    cost_excl = parseFloat(cost_excl).toFixed(3);
    cost_vat = parseFloat(cost_vat).toFixed(3);
    setTotals({ cost_incl, cost_excl, cost_vat, quantity });
  };

  return (
    <Box p={4} borderWidth={1} borderRadius="md" bg="white">
      <Box mb={4}>
        <Table variant="striped" size="sm" colorScheme="gray" borderColor="gray.200" borderWidth="2px" borderStyle="solid">
          <Thead>
            <Tr>
              <Th>PO Number</Th>
              <Th>Order Date</Th>
              <Th>Delivery Date</Th>
              <Th>Store Code</Th>
              <Th>Store Name</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr key={1}>
              <Td>{step1Data.po_number}</Td>
              <Td>{step1Data.order_date}</Td>
              <Td>{step1Data.delivery_date}</Td>
              <Td>{step1Data.store_code}</Td>
              <Td>{step1Data.store_name}</Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
      <Box mb={4}>
        <Table variant="striped" size="sm" colorScheme="gray" borderColor="gray.200" borderWidth="2px" borderStyle="solid">
          <Thead>
            <Tr>
              <Th>Article Number</Th>
              <Th>Article Description</Th>
              <Th>Pack Size</Th>
              <Th>Quantity</Th>
              <Th>Cost Price(excl)</Th>
              <Th>VAT</Th>
              <Th>Cost Price(incl)</Th>
            </Tr>
          </Thead>
          <Tbody>
            {step2Data.map((article, index) => (
              <Tr key={index}>
                <Td>{article.articlecode}</Td>
                <Td>{article.article_desc}</Td>
                <Td>{article.packsize}</Td>
                <Td>{article.quantity}</Td>
                <Td>{article.cost_excl}</Td>
                <Td>{article.cost_vat}</Td>
                <Td>{article.cost_incl}</Td>
              </Tr>
            ))}
            <Tr>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td style={{ fontWeight: "bold" }}>{totals.quantity}</Td>
              <Td style={{ fontWeight: "bold" }}>{totals.cost_excl}</Td>
              <Td style={{ fontWeight: "bold" }}>{totals.cost_vat}</Td>
              <Td style={{ fontWeight: "bold" }}>{totals.cost_incl}</Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
      <Box mb={4}>
        {!orderDetails && (
          <Button onClick={handleSubmit} isLoading={loading} loadingText="Submitting..." colorScheme="customRed" variant="outline">
            Submit
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default CaptureStep3;
