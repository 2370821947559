import React, { useState } from "react";
//import axios from "axios";
import { Box, useTheme } from "@chakra-ui/react";

import { useSelection } from "../../wrappers/SelectionContext";

import SubNav from "../../components/SubNav";
import Import from "./components/import/Import";
import Summary from "./components/capture/Summary";
import History from "./components/history/History";

const Orders = () => {
  const theme = useTheme();
  const { selectedWarehouse, selectedSupplier, selectedRetailer, selectedOrganisation, selectedDistributor } = useSelection();

  const tabData = [
    {
      label: "Capture",
      component: <Summary warehouse={selectedWarehouse} supplier={selectedSupplier} retailer={selectedRetailer} organisation={selectedOrganisation} distributor={selectedDistributor} />,
    },
    {
      label: "Imports",
      component: <Import warehouse={selectedWarehouse} supplier={selectedSupplier} retailer={selectedRetailer} organisation={selectedOrganisation} distributor={selectedDistributor} />,
    },
    {
      label: "History",
      component: <History organisation={selectedOrganisation} distributor={selectedDistributor} supplier={selectedSupplier} retailer={selectedRetailer} />,
    },
  ];

  return (
    <Box p={4}>
      <SubNav mt={12} data={tabData} />
    </Box>
  );
};

export default Orders;
