import React, { useState } from "react";
import { Box, Button, Flex } from "@chakra-ui/react";
import { FaArrowRight } from "react-icons/fa";

function ExpandingButtonGroup({ onActiveButtonChange }) {
  const [isHovered, setIsHovered] = useState(false);
  const [activeButton, setActiveButton] = useState("GRV"); // State to track the active button

  const handleSetActiveButton = (button) => {
    setActiveButton(button);
    onActiveButtonChange(button); // Notify the parent component about the change
  };

  return (
    <Box onMouseEnter={() => setIsHovered(true)} 
    onMouseLeave={() => setIsHovered(false)} width="auto" transition="width 0.3s ease-in-out">
      <Flex>
        <Button rightIcon={<FaArrowRight />} colorScheme="customRed" variant="outline" onClick={() => handleSetActiveButton("GRV")} mr={2}>
          {activeButton}
        </Button>
        {isHovered && (
          <>
            {activeButton !== "GRV" && (
              <Button mr={2} colorScheme="blackAlpha" variant={activeButton === "GRV" ? "solid" : "outline"} onClick={() => handleSetActiveButton("GRV")}>
                GRV
              </Button>
            )}
            {activeButton !== "Stock Transfer" && (
              <Button mr={2} colorScheme="blackAlpha" variant={activeButton === "Stock Transfer" ? "solid" : "outline"} onClick={() => handleSetActiveButton("Stock Transfer")}>
                Stock Transfer
              </Button>
            )}
            {activeButton !== "Returns" && (
              <Button mr={2} colorScheme="blackAlpha" variant={activeButton === "Returns" ? "solid" : "outline"} onClick={() => handleSetActiveButton("Returns")}>
                Returns
              </Button>
            )}

            {activeButton !== "Dispatch" && (
              <Button mr={2} colorScheme="blackAlpha" variant={activeButton === "Dispatch" ? "solid" : "outline"} onClick={() => handleSetActiveButton("Dispatch")}>
                Dispatch
              </Button>
            )}
          </>
        )}
      </Flex>
    </Box>
  );
}

export default ExpandingButtonGroup;
