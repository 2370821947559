import React, { createContext, useContext, useState } from "react";
import axios from "axios";

const UserAccessContext = createContext();

export const useUserAccess = () => useContext(UserAccessContext);

export const UserAccessProvider = ({ children }) => {
  const [user_page_access, setUser_page_access] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchPageAccess = async ({ userid, isSuperAdmin, isOrganisation, isSupplier, isRetailer }) => {
    setLoading(true);
    try {
      if (isSuperAdmin) {
        setUser_page_access([{ value: "Orders" }, { value: "Invoices" }, { value: "Stock" }, { value: "Debriefs" }, { value: "Admin" }, { value: "SuperAdmin" }]);
      } else if (isOrganisation) {
        setUser_page_access([{ value: "Orders" }, { value: "Invoices" }, { value: "Stock" }, { value: "Debriefs" }, { value: "Admin" }]);
      } else if (isSupplier) {
        setUser_page_access([{ value: "Orders" }, { value: "Invoices" }, { value: "Stock" }, { value: "Debriefs" }]);
      } else if (isRetailer) {
        setUser_page_access([{ value: "Orders" }, { value: "Invoices" }, { value: "Stock" }, { value: "Debriefs" }]);
      } else {
        alert("page access error");
      }
    } catch (error) {
      console.error("Error fetching page access", error);
    } finally {
      setLoading(false);
    }
  };

  const resetContext = () => {
    setUser_page_access([]);
    setLoading(false);
  };

  return <UserAccessContext.Provider value={{ user_page_access, fetchPageAccess, loading, resetContext }}>{children}</UserAccessContext.Provider>;
};
