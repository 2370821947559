import React, { useEffect, useState } from "react";
import { Button, Menu, MenuButton, MenuList, MenuItem, Image, Box, useTheme } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useSelection } from "../wrappers/SelectionContext";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../tools/firebase";

const OrganisationNavigation = () => {
  const theme = useTheme();
  const { selectedOrganisation, setSelectedOrganisation, organisationAccess } = useSelection();
  const [logos, setLogos] = useState({ chainStoreSolutions: "/chainStoreSolutions.jpg" });

  const handleOrganisationChange = (distributor) => {
    setSelectedOrganisation(distributor);
  };

  return (
    <Box display="flex" alignItems="center" m={1} width="calc(100% - 8px)" mx="4px">
      <Menu>
        <MenuButton as={Button} rightIcon={<ChevronDownIcon />} bg={"white"} _hover={{ bg: `${theme.colors.secondary1} !important`, color: "black !important" }} color="black" maxWidth="100%" minWidth="100%" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" px={1} justifyContent="space-between">
          {selectedOrganisation}
        </MenuButton>
        <MenuList bg={"white"} color="white" maxWidth="100%" maxHeight="500px" overflowY="auto">
          {organisationAccess.map((distributor) => (
            <MenuItem key={distributor.value} onClick={() => handleOrganisationChange(distributor.value)} bg="white" _hover={{ bg: `${theme.colors.secondary2} !important`, color: "white !important" }} color="black" maxWidth="100%" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" minH="40px">
              {logos[distributor.value] && <Image boxSize="2rem" borderRadius="full" src={logos[distributor.value]} alt={distributor.text} mr="12px" />}
              <span>{distributor.text}</span>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  );
};

export default OrganisationNavigation;
