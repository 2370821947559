import React, { useState, useEffect } from "react";
import axios from "axios";
import { Flex, Box, Image, Table, Thead, Tbody, Tr, Th, Td, Button, IconButton, Input, Switch, useToast, Spinner, Select } from "@chakra-ui/react";
import { FaPlus, FaEdit, FaSave, FaUpload } from "react-icons/fa";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../../tools/firebase";

const Distributors = ({ userid, organisation, supplier, retailer, fetchDistributorAccess }) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [logoFetchInProgress, setLogoFetchInProgress] = useState(false);
  const [uploadingIndex, setUploadingIndex] = useState(null);

  const fetchDistributors = async () => {
    try {
      setLoading(true);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/get-distributors`, { userid, organisation });
      setLoading(false);
      if (response.data.status === "success") {
        setData(response.data.data.distributors);
        setWarehouses(response.data.data.dd_warehouses);
        setLogoFetchInProgress(true); 
      } else {
        toast({
          title: "Failed to load distributors",
          description: response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: "Failed!",
        description: "Technical Error Occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (logoFetchInProgress) {
      fetchLogos();
      setLogoFetchInProgress(false); // Prevent further triggers until next data load
    }
  }, [logoFetchInProgress]);

  const fetchLogos = async () => {
    const logoUrls = [...data];
    for (let i = 0; i < logoUrls.length; i++) {
      const logoRef = ref(storage, `distributor_logos/${logoUrls[i].distributorcode}`);
      try {
        const url = await getDownloadURL(logoRef);
        logoUrls[i].dist_logo = url;
      } catch (error) {
        logoUrls[i].dist_logo = "/path/to/default/logo.jpg"; // Use a default path
      }
    }
    setData(logoUrls);
  };

  useEffect(() => {
    fetchDistributors();
  }, [organisation, retailer]);

  const addRow = () => {
    setData([
      ...data,
      {
        id: data.length + 1,
        distributorcode: "",
        dist_name: "",
        dist_location: "",
        dist_volumne:"",
        dist_contact: "",
        dist_logo: "",
        activeyn: true,
        isEditing: true,
      },
    ]);
  };

  const editRow = (index) => {
    const newData = [...data];
    newData[index].isEditing = true;
    setData(newData);
  };
  const saveRow = async (index) => {
    setUploadingIndex(index);
    const file = data[index].logoFile; // Assuming you've set up logoFile during file selection
    const distributor = data[index];
    if (file) {
      const logoRef = ref(storage, `distributor_logos/${distributor.distributorcode}`);
      const uploadTask = uploadBytesResumable(logoRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
        },
        (error) => {
          console.error("Upload failed:", error);
          toast({
            title: "Upload failed",
            description: "Failed to upload logo",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          setUploadingIndex(null);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            const updatedData = data.map((item, idx) => {
              if (idx === index) {
                return { ...item, dist_logo: downloadURL };
              }
              return item;
            });
            setData(updatedData);
            // Proceed to save other distributor details to the backend
            saveRow_backend(index, distributor.distributorcode);
          });
        }
      );
    } else {
      // If no new file was uploaded but we're saving changes, just proceed with backend save
      saveRow_backend(index, distributor.distributorcode);
    }
  };

  const saveRow_backend = async (index) => {
    try {
      const newData = [...data];
      newData[index].isEditing = false;
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/ups-distributor`, { userid, organisation, distributor: newData[index] });
      const { status, title, message } = response.data;
      toast({
        title: title,
        description: message,
        status: status,
        duration: 1500,
        isClosable: true,
      });
      setData(newData);
      await fetchDistributorAccess();
    } catch (error) {
      const errMsg = error.response?.data?.data?.error || "Technical Error Occurred";
      toast({
        title: "Failed!",
        description: errMsg,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setUploadingIndex(null);
    }
  };

  const handleChange = (index, field, value) => {
    const newData = [...data];
    if (field === "logoFile") {
      newData[index].logoFile = value;
      newData[index].dist_logo = value ? URL.createObjectURL(value) : newData[index].dist_logo;
    } else {
      newData[index][field] = value;
    }
    setData(newData);
  };

  return (
    <Table variant="striped" size="sm" colorScheme="gray" borderColor="gray.200" borderWidth="2px" borderStyle="solid">
      <Thead>
        <Tr>
          <Th>Distributor Code</Th>
          <Th>Distributor Name</Th>
          <Th>Warehouse</Th>
          <Th>Location</Th>
          <Th>Volumetric Ratio</Th>
          <Th>Contact</Th>
          <Th>Logo</Th>
          <Th>Active</Th>
          <Th style={{ width: "150px" }}>
            <Button rightIcon={<FaPlus />} onClick={addRow} ml={2} colorScheme="customRed" variant="outline" size="xs">
              Add
            </Button>
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {data.map((row, index) => (
          <Tr key={row.id}>
            <Td>{row.isEditing ? <Input type="text" width="150px" value={row.distributorcode} onChange={(e) => handleChange(index, "distributorcode", e.target.value)} /> : row.distributorcode}</Td>
            <Td>{row.isEditing ? <Input type="text" width="150px" value={row.dist_name} onChange={(e) => handleChange(index, "dist_name", e.target.value)} /> : row.dist_name}</Td>
            <Td>
              {row.isEditing ? (
                <Select
                  placeholder="Select warehouse"
                  value={row.warehousecode}
                  onChange={(e) => {
                    warehouses.forEach((warehouse) => (row[warehouse.value] = false));
                    row[e.target.value] = true;
                    handleChange(index, "warehousecode", e.target.value);
                  }}
                >
                  {warehouses.map((warehouse) => (
                    <option key={warehouse.value} value={warehouse.value}>
                      {warehouse.text}
                    </option>
                  ))}
                </Select>
              ) : (
                row.warehousecode
              )}
            </Td>
            <Td>{row.isEditing ? <Input type="text" width="150px" value={row.dist_location} onChange={(e) => handleChange(index, "dist_location", e.target.value)} /> : row.dist_location}</Td>
            <Td>{row.isEditing ? <Input type="text" width="150px" value={row.dist_volumne} onChange={(e) => handleChange(index, "dist_volumne", e.target.value)} /> : row.dist_volumne}</Td>
            <Td>{row.isEditing ? <Input type="text" width="150px" value={row.dist_contact} onChange={(e) => handleChange(index, "dist_contact", e.target.value)} /> : row.dist_contact}</Td>
            <Td text-align="center" style={{ maxWidth: "100px", overflow: "hidden" }}>
              {row.isEditing ? (
                <Flex align="center" alignItems="center" justifyContent="space-between">
                  <IconButton icon={<FaUpload />} onClick={() => document.getElementById(`fileInput-${index}`).click()} size="sm" flexShrink={0} />
                  <Input id={`fileInput-${index}`} type="file" accept="image/*" onChange={(e) => handleChange(index, "logoFile", e.target.files[0])} display="none" />
                  {row.logoFile && (
                    <Box boxShadow="sm" p="2" rounded="md" bg="gray.50" flexGrow={1}>
                      <Image src={row.dist_logo} alt="Preview" width="75%" height="50px" objectFit="contain" rounded="md" />
                    </Box>
                  )}
                </Flex>
              ) : (
                <Box boxShadow="sm" p="2" rounded="md" bg="gray.50" width="100%">
                  <Image src={row.dist_logo} alt="Distributor Logo" width="50%" height="50px" objectFit="contain" rounded="md" />
                </Box>
              )}
            </Td>
            <Td>{row.isEditing ? <Switch isChecked={row.activeyn} onChange={(e) => handleChange(index, "activeyn", e.target.checked)} colorScheme="green" /> : <Switch isChecked={row.activeyn} isReadOnly colorScheme={row.activeyn ? "green" : "red"} />}</Td>
            <Td>{row.isEditing ? <IconButton icon={uploadingIndex === index ? <Spinner size="sm" /> : <FaSave />} onClick={() => saveRow(index)} ml={2} size="sm" isDisabled={uploadingIndex !== null} /> : <IconButton icon={<FaEdit />} onClick={() => editRow(index)} ml={2} size="sm" />}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export default Distributors;
