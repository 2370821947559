import React, { useState, useEffect } from "react";
import { Routes, Route, NavLink, useLocation, Navigate } from "react-router-dom";
import { FaRegListAlt, FaWarehouse, FaFileInvoiceDollar, FaRegFileAlt, FaUserCog, FaSignOutAlt, FaSignInAlt } from "react-icons/fa";
import { Flex, Box, Text, Button, Image, useTheme, Link, Divider } from "@chakra-ui/react";
import { Helmet } from "react-helmet";

import Signin from "./pages/login/Signin";

import Orders from "./pages/orders/Orders";
import Stock from "./pages/stock/Stock";
import Invoices from "./pages/invoices/Invoices";
import Debriefs from "./pages/debriefs/Debriefs";
import Admin from "./pages/admin/Admin";

import { useAuthentication } from "./wrappers/UseAuthentication";
import { useSelection } from "./wrappers/SelectionContext";
import { useUserAccess } from "./wrappers/UserAccessContext";

import RetailerNavigation from "./navigations/RetailerNavigation";
import SupplierNavigation from "./navigations/SupplierNavigation";
import WarehouseNavigation from "./navigations/WarehouseNavigation";
import DistributorNavigation from "./navigations/DistributorNavigation";
import OrganisationNavigation from "./navigations/OrganisationNavigation";
// import SupplierLogo from "./components/SupplierLogo";

const Layout = () => {
  const { isAuthenticated, signOut } = useAuthentication();
  const { retailers, user_supplier_access, loading } = useSelection();
  const { user_page_access, loading: pageLoading } = useUserAccess();
  const theme = useTheme();
  const location = useLocation();

  const linkStyles = {
    marginRight: "20px",
    textDecoration: "none",
    color: "black",
    _hover: {
      color: "red",
      textDecoration: "none",
    },
  };

  const getLinkStyles = (path) => ({
    color: location.pathname === path ? "white" : "black",
    _hover: {
      color: theme.colors.secondary2,
    },
  });

  const [adminImage, setAdminImage] = useState("/chainStoreSolutions.jpg");
  useEffect(() => {
    const userid = localStorage.getItem("userid");
    if (userid == 5 || userid == "5") {
      setAdminImage("/DAWN3.jpg");
      //setAdminImage('/spearicon.jpg');
    }
  }, []);

  return (
    <Flex minHeight="100vh" overflow="hidden">
      <Flex as="nav" direction="column" bgGradient="linear(to-b, #93291E,#84200e, #57180a)" color="white" width="150px" height="100vh" position="fixed" borderRadius="md">
        <Box display="flex" alignItems="center" minHeight="111.73" bg="white" pb={0.5}>
          <Image src={adminImage} alt="Logo" objectFit="cover" borderRadius="md" />
        </Box>
        {isAuthenticated && !pageLoading && user_page_access.length > 0 && (
          <>
            {user_supplier_access.length > 0 && <SupplierNavigation />}
            {user_supplier_access.length > 0 && <WarehouseNavigation />}
            {user_supplier_access.length > 0 && <DistributorNavigation />}
            {user_page_access.some((page) => page.value === "SuperAdmin") && <OrganisationNavigation />}

            {user_page_access.some((page) => page.value === "Orders") && (
              <Link as={NavLink} to="/orders" sx={{ ...linkStyles, ...getLinkStyles("/orders") }}>
                <Box display="flex" alignItems="center" mb={4} pl={4} mt={4}>
                  <FaRegListAlt />
                  <Text ml={2}>Orders</Text>
                </Box>
              </Link>
            )}
            {user_page_access.some((page) => page.value === "Invoices") && (
              <Link as={NavLink} to="/invoices" sx={{ ...linkStyles, ...getLinkStyles("/invoices") }}>
                <Box display="flex" alignItems="center" mb={4} pl={4} mt={4}>
                  <FaFileInvoiceDollar />
                  <Text ml={2}>Invoices</Text>
                </Box>
              </Link>
            )}

            {user_page_access.some((page) => page.value === "Debriefs") && (
              <Link as={NavLink} to="/debriefs" sx={{ ...linkStyles, ...getLinkStyles("/debriefs") }}>
                <Box display="flex" alignItems="center" mb={4} pl={4} mt={4}>
                  <FaRegFileAlt />
                  <Text ml={2}>Debriefs</Text>
                </Box>
              </Link>
            )}
            {user_page_access.some((page) => page.value === "Stock") && (
              <Link as={NavLink} to="/stock" sx={{ ...linkStyles, ...getLinkStyles("/stock") }}>
                <Box display="flex" alignItems="center" mb={4} pl={4} mt={4}>
                  <FaWarehouse />
                  <Text ml={2}>Stock</Text>
                </Box>
              </Link>
            )}

            <Divider mb={4} />
            {user_page_access.some((page) => page.value === "Admin") && (
              <Link as={NavLink} to="/admin" sx={{ ...linkStyles, ...getLinkStyles("/admin") }}>
                <Box display="flex" alignItems="center" mb={4} pl={4}>
                  <FaUserCog />
                  <Text ml={2}>Admin</Text>
                </Box>
              </Link>
            )}

            {/* <Divider mb={4} /> */}

            <Button leftIcon={<FaSignOutAlt />} onClick={signOut} variant="outline" colorScheme="customRed" position="fixed" bottom="10px" left="17px" zIndex="1000">
              Logout
            </Button>
          </>
        )}
        {!isAuthenticated && (
          // <NavLink to="/signin" style={{ marginRight: "20px" }}>
          //   <Text>Sign In</Text>
          // </NavLink>
          <>
            <Link as={NavLink} to="/signin" sx={{ ...linkStyles, ...getLinkStyles("/signin") }}>
              <Box display="flex" alignItems="center" mb={4} pl={4} mt={4}>
                <FaSignInAlt />
                <Text ml={2}>Sign In</Text>
              </Box>
            </Link>
            {/* <Link as={NavLink} to="/contact-us" sx={{ ...linkStyles, ...getLinkStyles("/contact-us") }}>
              <Box display="flex" alignItems="center" mb={4} pl={4}>
                <FaEnvelope />
                <Text ml={2}>Contact Us</Text>
              </Box>
            </Link>
            <Link as={NavLink} to="/about-us" sx={{ ...linkStyles, ...getLinkStyles("/about-us") }}>
              <Box display="flex" alignItems="center" mb={4} pl={4}>
                <FaInfoCircle />
                <Text ml={2}>About Us</Text>
              </Box>
            </Link> */}
          </>
        )}
      </Flex>

      <Flex direction="column" flex="1" overflow="auto" ml="150px">
        {/* <SupplierLogo /> */}
        <Box p={4}>
          {isAuthenticated && !loading && retailers.length > 0 && <RetailerNavigation />}
          <Routes>
            <Route path="/signin" element={<Signin />} />
            {isAuthenticated && (
              <>
                <Route
                  path="/orders"
                  element={
                    <>
                      <Helmet>
                        <title>Orders - Spearbi</title>
                        <meta name="description" content="Orders XXX" />
                      </Helmet>
                      <Orders />
                    </>
                  }
                />
                <Route
                  path="/stock"
                  element={
                    <>
                      <Helmet>
                        <title>Stock - Spearbi</title>
                        <meta name="description" content="Analyze your Stock data comprehensively with Spearbi's Stock system tools." />
                      </Helmet>
                      <Stock />
                    </>
                  }
                />
                <Route
                  path="/invoices"
                  element={
                    <>
                      <Helmet>
                        <title>Invoices - Spearbi</title>
                        <meta name="description" content="Invoices XXX" />
                      </Helmet>
                      <Invoices />
                    </>
                  }
                />
                <Route
                  path="/debriefs"
                  element={
                    <>
                      <Helmet>
                        <title>Debriefs - Spearbi</title>
                        <meta name="description" content="Debriefs XXX" />
                      </Helmet>
                      <Debriefs />
                    </>
                  }
                />
                <Route
                  path="/admin"
                  element={
                    <>
                      <Helmet>
                        {" "}
                        <title>Admin - Spearbi</title> <meta name="description" content="Admin XXX" />{" "}
                      </Helmet>
                      <Admin />
                    </>
                  }
                />
              </>
            )}
            <Route path="*" element={<Navigate to="/signin" replace />} />
            <Route path="/" element={<Signin />} />
          </Routes>
        </Box>
      </Flex>
    </Flex>
  );
};

export default Layout;
