import { useState,useEffect } from "react";
import { Button, Spinner, useToast } from "@chakra-ui/react";
import { FaDownload } from "react-icons/fa";
import axios from "axios";

const FileDownload = ({ sender, organisation, retailer = null, warehouse = null, distributor = null, supplier = null }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [fileName,setFilename]=useState(`${sender}_${retailer}.xlsx`)
  const toast = useToast();

  useEffect(() => {
    setFilename(`${sender}_${retailer}.xlsx`);
  }, [retailer]);

  const handleFileDownload = async () => {
    setIsDownloading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/download_${sender}`,
        {
          retailercode:retailer,
          organisationcode:organisation,
          warehousecode:warehouse,
          distributorcode:distributor,
        },
        {
          responseType: 'blob', // Important for handling binary data
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();

      toast({
        title: "File Download Successful",
        description: `${fileName} has been downloaded.`,
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      const errMsg = error.response?.data?.message || "Technical Error Occurred";
      toast({
        title: "File Download Failed",
        description: errMsg,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <div>
      <Button mt={4} leftIcon={isDownloading ? <Spinner /> : <FaDownload />} onClick={handleFileDownload} isLoading={isDownloading} loadingText="Downloading" cursor="pointer" colorScheme="customRed" variant="outline">
        Download {fileName}
      </Button>
    </div>
  );
};

export default FileDownload;
