import React, { useState, useEffect } from "react";
import { Table, Thead, Tbody, Tr, Th, Td, IconButton, Spinner, Flex, Box, Button, useTheme, useToast } from "@chakra-ui/react";
import { FaEye, FaPrint } from "react-icons/fa";
import axios from "axios";
import ViewInvoice from "./ViewInvoice";

const InvoicedTable = ({ organisation, warehouse, supplier, retailer }) => {
  const theme = useTheme();
  const toast = useToast();

  const [loading, setLoading] = useState(true);
  const [invoices, setInvoices] = useState([]);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);

  const [page, setPage] = useState(0); // Add state for current page
  const [limit] = useState(50);
  const [totalCount, setTotalCount] = useState(0);

  const handleViewInvoice = (invoice) => {
    setSelectedInvoice(invoice);
    setIsPopupOpen(true);
  };

  const handleCloseInvoice = () => {
    setIsPopupOpen(false);
    setSelectedInvoice(null);
  };

  const fetchInvoices = async () => {
    setLoading(true);

    const response = await axios.post(`${process.env.REACT_APP_API_URL}/invoices/get-cancelled`, { organisation, retailer, supplier, limit, offset: page * limit });
    if (response.data.status != "success") {
      toast({
        title: response.data.title,
        description: response.data.message,
        status: response.data.status,
        duration: 2500,
        isClosable: true,
      });
      setLoading(false);
      return;
    }

    setInvoices(response.data.data.orders);
    setTotalCount(response.data.data.totalCount);
    setLoading(false);
  };

  useEffect(() => {
    fetchInvoices();
  }, [supplier, warehouse, retailer]);
  if (loading) return <Spinner />;

  const handlePageChange = (newPage) => {
    if (newPage >= 0 && newPage * limit < totalCount) {
      setPage(newPage);
      fetchInvoices();
    }
  };

  return (
    <Flex flexDirection={"column"}>
      <Box maxHeight="640px" overflowY={"scroll"}>
        <Table variant="striped" size="sm" colorScheme="gray" borderColor="gray.200" borderWidth="2px" borderStyle="solid">
          <Thead>
            <Tr>
              <Th>View</Th>
              <Th>Print</Th>
              <Th>Supplier</Th>
              <Th>Invoice #</Th>
              <Th>Po #</Th>
              <Th>Store Code</Th>
              <Th>Store Name</Th>
              <Th>Region</Th>
              <Th>Distributor</Th>
              <Th># Cases</Th>
              <Th>Delivery Date</Th>
              <Th>Invoice Amount (incl)</Th>
              <Th>Comment</Th>
            </Tr>
          </Thead>
          <Tbody>
            {invoices.map((invoice, index) => (
              <Tr key={invoice.id}>
                <Td>
                  <IconButton variant="outline" colorScheme="customRed" icon={<FaEye />} size="sm" aria-label="View" onClick={() => handleViewInvoice(invoice)} />{" "}
                </Td>
                <Td>
                  <IconButton variant="outline" colorScheme="gray" icon={<FaPrint />} size="sm" onClick={() => console.log("print")} />
                </Td>
                <Td>{invoice.supplier_name}</Td>
                <Td>{invoice.order_code}</Td>
                <Td>{invoice.po_number}</Td>
                <Td>{invoice.store_code}</Td>
                <Td>{invoice.store_name}</Td>
                <Td>{invoice.store_classification}</Td>
                <Td>{invoice.dist_name}</Td>
                <Td>{invoice.cases}</Td>
                <Td>{invoice.delivery_date}</Td>
                <Td>{invoice.cost_incl}</Td>
                <Td>{invoice.comment}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        {selectedInvoice && <ViewInvoice isOpen={isPopupOpen} onClose={handleCloseInvoice} invoice={selectedInvoice} />}
      </Box>
      <Flex justifyContent="space-between" mt={4}>
        <Button
          onClick={() => handlePageChange(page - 1)}
          isDisabled={page === 0} // Disable Previous button if on the first page
        >
          Previous
        </Button>
        <Button
          onClick={() => handlePageChange(page + 1)}
          isDisabled={(page + 1) * limit >= totalCount} // Disable Next button if there are no more invoices
        >
          Next
        </Button>
      </Flex>
    </Flex>
  );
};

export default InvoicedTable;
