import React, { useState, useEffect } from "react";
import { Box, Heading, Table, Thead, Tbody, Tr, Th, Td, useTheme, Spinner } from "@chakra-ui/react";

const Summary = () => {
  const theme = useTheme();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const hardcodedData = [
        { article: "A001", soh: 100, invoiced: 50, available: 50, orders: 30, damagedWh: 5, stockTransfer: 15 },
        { article: "A002", soh: 200, invoiced: 150, available: 50, orders: 60, damagedWh: 10, stockTransfer: 30 },
        { article: "A003", soh: 300, invoiced: 200, available: 100, orders: 90, damagedWh: 15, stockTransfer: 45 },
        { article: "A004", soh: 400, invoiced: 250, available: 150, orders: 120, damagedWh: 20, stockTransfer: 60 },
      ];
      // Simulate fetching data
      setTimeout(() => {
        setTableData(hardcodedData);
        setLoading(false);
      }, 1000);
    };
    fetchData();
  }, []);

  if (loading) return <Spinner />;

  return (
    <Box>
      {/* <Heading size="md" textAlign="center" mb={4}  color={"white"} p={1}>
        Summary
      </Heading> */}
      <Table variant="striped" size="sm" colorScheme="gray" borderColor="gray.200" borderWidth="2px" borderStyle="solid">
        <Thead>
          <Tr>
            <Th>Article #</Th>
            <Th>SOH</Th>
            <Th>Invoiced</Th>
            <Th>Available</Th>
            <Th>Orders</Th>
            <Th>Damaged WH</Th>
            <Th>Stock Transfer</Th>
          </Tr>
        </Thead>
        <Tbody>
          {tableData.map((row, index) => (
            <Tr key={index}>
              <Td>{row.article}</Td>
              <Td>{row.soh}</Td>
              <Td>{row.invoiced}</Td>
              <Td>{row.available}</Td>
              <Td>{row.orders}</Td>
              <Td>{row.damagedWh}</Td>
              <Td>{row.stockTransfer}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default Summary;
