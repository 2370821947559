import React, { useState } from "react";
import { Flex, Input, Button, IconButton, Box, Select, CloseButton, Tag, TagLabel, TagCloseButton } from "@chakra-ui/react";
import { FaFilter } from "react-icons/fa";

const FilteringOrders = ({ onFilterSubmit }) => {
  const [showFilters, setShowFilters] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [order_codes, setOrder_codes] = useState(["12345", "67890", "54321"]); 
  const [po_numbers, setPo_number] = useState(["PO001", "PO002", "PO003"]); 
  const [store_codes, setStore_codes] = useState(["SCO001", "SCO002", "SCO003"]); 
  const [store_descriptions, setStore_descriptions] = useState(["SDO001", "SDO002", "SDO003"]); 
  const [filterOptions, setFilterOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const toggleFilters = () => {
    setShowFilters((prev) => !prev);
  };

  const handleFilterChange = (e) => {
    setSelectedFilter(e.target.value);
    if (e.target.value === "order_code") {
      setFilterOptions(order_codes);
    } else if (e.target.value === "po_number") {
      setFilterOptions(po_numbers);
    } else if (e.target.value === "store_code") {
      setFilterOptions(store_codes);
    } else if (e.target.value === "store_description") {
      setFilterOptions(store_descriptions);
    }
  };

  const handleAddFilter = (filterType, filterValue) => {
    setSelectedFilters((prevFilters) => [...prevFilters, { type: filterType, value: filterValue }]);
    setSelectedFilter("");
    setFilterOptions([]);
    setSearchTerm("");
  };

  const handleRemoveFilter = (filterToRemove) => {
    setSelectedFilters((prevFilters) => prevFilters.filter((filter) => filter !== filterToRemove));
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    let dataToFilter = [];
    switch (selectedFilter) {
      case "order_code":
        dataToFilter = order_codes;
        break;
      case "po_number":
        dataToFilter = po_numbers;
        break;
      case "store_code":
        dataToFilter = store_codes;
        break;
      case "store_description":
        dataToFilter = store_descriptions;
        break;
      default:
        dataToFilter = [];
        break;
    }
    const filteredOptions = dataToFilter.filter((item) => item.toLowerCase().includes(e.target.value.toLowerCase()));
    setFilterOptions(filteredOptions);
  };

  const handleSubmit = () => {
    setShowFilters(false);
    onFilterSubmit(selectedFilters);
  };

  return (
    <Box position="relative">
      <IconButton icon={<FaFilter />} aria-label="Toggle Filters" onClick={toggleFilters} mb={1} variant="outline" colorScheme="blue" />

      {showFilters && (
        <Box position="absolute" top="-20%" left="30%" width="600px" height="600px" backgroundColor="rgba(255, 255, 255, 0.85)" display="flex" justifyContent="center" alignItems="center" zIndex="1000" boxShadow="md">
          <Box backgroundColor="white" p={6} borderRadius="md" boxShadow="lg" width="300px" height="450px" position="relative">
            <CloseButton position="absolute" right="10px" top="10px" onClick={toggleFilters} />

            {/* Display Selected Filters */}
            <Flex mb={4} wrap="wrap" gap="8px">
              {selectedFilters.map((filter, index) => (
                <Tag key={index} size="md" colorScheme="blue" borderRadius="full">
                  <TagLabel>{`${filter.type}: ${filter.value}`}</TagLabel>
                  <TagCloseButton onClick={() => handleRemoveFilter(filter)} />
                </Tag>
              ))}
            </Flex>

            <Select placeholder="Select Filter" value={selectedFilter} onChange={handleFilterChange} mb={4}>
              <option value="order_code">Invoice Number</option>
              <option value="po_number">PO Number</option>
              <option value="store_code">Store Code</option>
              <option value="store_description">Store Name</option>
            </Select>

            {selectedFilter && (
              <Box mb={4}>
                <Input placeholder={`Search by ${selectedFilter}`} value={searchTerm} onChange={handleSearchChange} mb={2} />
                <Select placeholder={`Select ${selectedFilter}`} onChange={(e) => handleAddFilter(selectedFilter, e.target.value)}>
                  {filterOptions.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </Select>
              </Box>
            )}

            <Button variant="solid" colorScheme="teal" onClick={handleSubmit} width="100%">
              Submit
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default FilteringOrders;
