import React from "react";
import { Box, Flex, Stat, StatLabel, StatNumber, StatHelpText, useColorModeValue } from "@chakra-ui/react";
import { CheckCircleIcon, CloseIcon } from "@chakra-ui/icons";

const StockStats = ({ totalCases, totalQuantity }) => {
  const isBalanced = parseInt(totalCases) == parseInt(totalQuantity) ;
  const bgColor = useColorModeValue("gray.100", "gray.700");

  return (
    <Box p={4} bg={bgColor} borderRadius="md" boxShadow="md" minWidth="600px">
      <Flex justify="space-around" align="center">
        <Stat>
          <StatLabel>Total Cases</StatLabel>
          <StatNumber>{totalCases}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Total Article Quantity</StatLabel>
          <StatNumber>{totalQuantity}</StatNumber>
        </Stat>
        <Stat>
          {isBalanced ? (
            <>
              <Flex align="center">
                <CheckCircleIcon w={6} h={6} color="green.500" mr={2} />
                <StatHelpText color="green.500">Stock Item Balances</StatHelpText>
              </Flex>
            </>
          ) : (
            <>
              <Flex align="center">
                <CloseIcon w={5} h={5} color="red.500" mr={2} />
                <StatHelpText color="red.500">Stock Item Does Not Balance</StatHelpText>
              </Flex>
            </>
          )}
        </Stat>
      </Flex>
    </Box>
  );
};

export default StockStats;
