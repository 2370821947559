import React, { useState, useEffect } from "react";
import { Box, Flex, Button, Table, Thead, Tbody, Tr, Th, Td, useColorModeValue, useTheme } from "@chakra-ui/react";
import axios from "axios";
import InvoiceStats from "./InvoiceStats";

const CaptureStep2 = ({ debrief_items, debriefData, onSubmit }) => {
  const theme = useTheme();
  const grayColor = useColorModeValue("#e6e6e6", "#dedede");
  const [loading, setLoading] = useState(false);
  const [debriefDetails, setDebriefData] = useState(null);
  const [totals, setTotals] = useState({});
  const [stats, setStats] = useState({});
  const [unbalancedRows, setUnbalancedRows] = useState([]);
  const [balance, setBalance] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      // Simulate axios request
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/debriefs/upd-in-progress-debrief`, {
        cases_delivered: totals.totalDelivered,
        cases_returned:totals.casesDebriefed,
        invoice_number: debriefData.invoice_number,
      });

      const debrief = {
        casesDebriefed: totals.casesDebriefed,
        totalGoodStock: totals.totalGoodStock,
        totalBadStock: totals.totalBadStock,
        totalMising: totals.totalMising,
        evaluated:true,
        articles: [...debrief_items],
      };

      setDebriefData(debrief);
      onSubmit(debrief);

      setLoading(false);
    } catch (error) {
      console.error("An error occurred:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    calculateTotals();
  }, [debrief_items]);

  const calculateTotals = () => {
    let totalDelivered = 0;
    let totalGoodStock = 0;
    let totalBadStock = 0;
    let totalMissing = 0;
    let stats = {};
    let unbalanced_rows = [];
    for (let i = 0; i < debrief_items.length; i++) {
      let quantity = parseInt(debrief_items[i].quantity);

      let good_stock = parseInt(debrief_items[i].good_stock);
      let bad_stock = parseInt(debrief_items[i].bad_stock);
      let missing_stock = parseInt(debrief_items[i].missing_stock);
      let delivered_stock = parseInt(debrief_items[i].delivered_stock);

      let invoiced_cases = good_stock + bad_stock + missing_stock + delivered_stock;

      stats[debrief_items[i].articlecode] = { invoiced_cases, quantity, index: i }; //must match

      if (invoiced_cases !== quantity) {
        unbalanced_rows.push(debrief_items[i].articlecode);
      }

      totalGoodStock += parseInt(debrief_items[i].good_stock);
      totalBadStock += parseInt(debrief_items[i].bad_stock);
      totalMissing += parseInt(debrief_items[i].missing_stock);
      totalDelivered += parseInt(debrief_items[i].delivered_stock);
    }
    setUnbalancedRows(unbalanced_rows);
    let casesDebriefed = totalBadStock + totalGoodStock + totalMissing;
    casesDebriefed = parseInt(casesDebriefed);
    totalGoodStock = parseInt(totalGoodStock);
    totalBadStock = parseInt(totalBadStock);
    totalMissing = parseInt(totalMissing);
    setStats(stats);
    setTotals({ totalDelivered, casesDebriefed, totalGoodStock, totalBadStock, totalMissing });

    if (debriefData.cases_invoiced == totalDelivered + casesDebriefed) {
      setBalance(true);
    } else {
      setBalance(false);
    }
  };

  return (
    <Box p={4} borderWidth={1} borderRadius="md" bg="white">
      <Box mb={4}>
        <Flex flexDirection={"row"} justifyContent={"space-between"}>
          <Table variant="striped" size="sm" maxWidth="300px" colorScheme="gray" borderColor="gray.200" borderWidth="2px" borderStyle="solid">
            <Thead>
              <Tr>
                <Th>Invoice Number</Th>
                <Th>PO Number</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr key={1}>
                <Td>{debriefData.invoice_number}</Td>
                <Td>{debriefData.po_number}</Td>
              </Tr>
            </Tbody>
          </Table>
          <InvoiceStats invoiced={debriefData.cases_invoiced} delivered_stock={totals.totalDelivered} notDelivered={totals.casesDebriefed} stats={stats} />
        </Flex>
      </Box>

      <Box mb={4}>
        <Table variant="striped" size="sm" colorScheme="gray" borderColor="gray.200" borderWidth="2px" borderStyle="solid">
          <Thead>
            <Tr>
              <Th bg={grayColor}></Th>
              <Th bg={grayColor}></Th>
              <Th bg={grayColor}></Th>
              {debriefData.partial_delivery && <Th>Delivered</Th>}
              <Th colSpan={3} bg={grayColor} style={{ textAlign: "center" }}>
                Not Delivered
              </Th>
            </Tr>
            <Tr>
              <Th>Article Number</Th>
              <Th>Article Name</Th>
              <Th>Invoiced cases</Th>
              {debriefData.partial_delivery && <Th></Th>}
              <Th>Good Stock</Th>
              <Th>Bad Stock</Th>
              <Th>Missing</Th>
            </Tr>
          </Thead>
          <Tbody>
            {debrief_items.map((article, index) => (
              <Tr
                key={index}
                sx={{
                  td: {
                    backgroundColor: unbalancedRows.includes(article.articlecode.toString().trim()) ? "rgba(204, 0, 0, 0.6) !important" : "inherit",
                  },
                }}
              >
                <Td> {article.articlecode} </Td>
                <Td> {article.article_name} </Td>
                <Td> {article.quantity} </Td>
                {debriefData.partial_delivery && <Td> {article.delivered_stock} </Td>}
                <Td>{article.good_stock}</Td>
                <Td>{article.bad_stock}</Td>
                <Td>{article.missing_stock}</Td>
              </Tr>
            ))}
            <Tr>
              <Td></Td>
              <Td></Td>
              <Td style={{ fontWeight: "bold" }}>{debriefData.cases_invoiced}</Td>
              {debriefData.partial_delivery && <Td style={{ fontWeight: "bold" }}> {totals.totalDelivered} </Td>}
              <Td style={{ fontWeight: "bold" }}>{totals.totalGoodStock}</Td>
              <Td style={{ fontWeight: "bold" }}>{totals.totalBadStock}</Td>
              <Td style={{ fontWeight: "bold" }}>{totals.totalMissing}</Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
      <Box mb={4}>
        {!debriefDetails && (
          <Button onClick={handleSubmit} isDisabled={!balance} isLoading={loading} loadingText="Submitting..." colorScheme="customRed" variant="outline">
            Submit
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default CaptureStep2;
