import React, { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelection } from "./SelectionContext";
import { useUserAccess } from "./UserAccessContext";

const AuthContext = createContext();

export const useAuthentication = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();
  const { resetContext: resetSupplierRetailerContext } = useSelection();
  const { resetContext: resetPageAccessContext } = useUserAccess();
  const signIn = () => {
    setIsAuthenticated(true);
    // resetSupplierRetailerContext();//@mike
    // resetPageAccessContext();//@mike
  };

  const signOut = () => {
    setIsAuthenticated(false);
    resetSupplierRetailerContext();
    resetPageAccessContext();
    localStorage.clear();
    navigate("/signin");
  };

  return <AuthContext.Provider value={{ isAuthenticated, signIn, signOut }}>{children}</AuthContext.Provider>;
};
