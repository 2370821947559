import React, { useState, useEffect } from "react";
import { Box, Button, Heading, Input, Spinner, useToast, useTheme } from "@chakra-ui/react";
import { FaFileUpload } from "react-icons/fa";
import axios from "axios";


const Import = ({ organisation, supplier, retailer,distributor }) => {
  const theme = useTheme();
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const toast = useToast();

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFileUpload = async(event) => {
    event.preventDefault(); // Prevent any default button click behavior
    setIsUploading(true);

    try{
      const formData = new FormData();
    formData.append("file", file); // This name must match the 'file' in upload.single('file')
    formData.append("organisationcode", organisation);
    formData.append("suppliercode", supplier);
    formData.append("retailercode", retailer);
    formData.append("distributorcode", distributor);
    formData.append("userid", localStorage.getItem("userid"));

    const response = await axios.post(`${process.env.REACT_APP_API_URL}/invoices/import-invoices`, formData);
    toast({
      title: response.data? response.data.title:"Success!",
      description: response.data? response.data.description:"Invoice imports successful!",
      status: "success",
      duration: 3000,
      isClosable: true,
    });

    // setIsUploading(false);
    // setFile(null);
    document.getElementById("file-input").value = "";
  } catch (error) {
    console.error("Error uploading file:", error);
    // setIsUploading(false);
    toast({
      title: "Upload failed.",
      description: "There was an error uploading the file.",
      status: "error",
      duration: 3000,
      isClosable: true,
    });
  }finally{
      setIsUploading(false);
      setFile(null); 
    }

  };

  return (
    <Box mt={4} p={4} boxShadow="md">
      <Heading size="md" mb={4}>
        Upload Excel File
      </Heading>
      <Input type="file" onChange={handleFileChange} display="none" id="file-input" />
      <label htmlFor="file-input">
        <Button
          as="span"
          mt={4}
          leftIcon={isUploading ? <Spinner /> : <FaFileUpload />}
          onClick={file ? handleFileUpload : undefined}
          isLoading={isUploading}
          loadingText="Uploading"
          colorScheme="customRed"
        variant="outline"
        >
          {file ? "Submit" : "Upload"}
        </Button>
      </label>
    </Box>
  );
};

export default Import;
